export default [
      {
        anchor: "#Landing",
        title: "Home",
      },
      {
        anchor: "#AccountDetails",
        title: "Suite Member<br />Account Details",
      },
      {
        anchor: "#Benefits",
        title: "Suite Benefits",
      },
      {
        anchor: "#PremiumTeam",
        title: "Indianapolis Colts<br />Premium Team",
      },
      {
        anchor: "#Schedule",
        title: "Team Schedule",
      },
      {
        anchor: "#ColtsGameDay",
        title: "All Things<br />Colts Gameday",
      },
      {
        anchor: "#Events",
        title: "Upcoming Events",
      },
      {
        anchor: "#Faq",
        title: "Colts Suite<br />Holder FAQs",
      },
    ];
  
  