<template>
  <section id="PremiumTeam">
    <div id="PremiumTeamMarker"></div>
    <div class="brick"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
      <div class="title">
        <h1>Indianapolis<br>Colts</h1>
        <img src="@/assets/PremiumTeam/PremiumTeam.png" alt="premium team">
        <p>Meet the individuals who are here for you every step of your suite membership journey.</p>
      </div>
      <div class="team">
        <div class="group">
          <div class="team__member">
            <img src="@/assets/PremiumTeam/Justin-Gentile.png" alt="justin gentile">
            <h3>Justin Gentile</h3>
            <div class="spacer"></div>
              <div class="info justin" :class="{ active: justin }">
                <div class="info-content" :class="{ active: !justin }">
                  <p>Director of Suite Sales & Service</p>
                  <a href="mailto:Justin.Gentile@colts.nfl.net">Justin.Gentile@colts.nfl.net</a>
                  <a href="tel:317-808-5242">317-808-5242</a>
                </div>
              <!-- <div class="info" :class="{ active: justin }"> -->
                <div class="info-content" :class="{ active: justin }">
                  <div class="information">
                    I joined the Colts in October of 2019. I've been working in the NFL for nearly ten
                    years, most recently with the Chicago Bears and Minnesota Vikings. As a graduate of the
                    University of Alabama, my favorite current Colts player is Ryan Kelly. Outside of a
                    passion for football, I enjoy spending time with my wife and our two dogs, traveling,
                    trying new restaurants, and playing video games.
                  </div>
                </div>
              </div>
            <button @click="justin = !justin">
              <p v-if="!justin">More Info</p>
              <p v-else>Close</p>
            </button>
          </div>
          <div class="team__member">
            <img src="@/assets/PremiumTeam/Hannah-Glaser.png" alt="hannah glaser">
            <h3>Hannah Glaser</h3>
            <div class="spacer"></div>
            <div class="info hannah" :class="{ active: hannah }">
              <div class="info-content" :class="{ active: !hannah }">
                <p>Suite Sales Manager</p>
                <a href="mailto:Hannah.Glaser@colts.nfl.net">Hannah.Glaser@colts.nfl.net</a>
                <a href="tel:317-808-5248">317-808-5248</a>
              </div>
              <div class="info-content" :class="{ active: hannah }">
                <div class="information">
                  I am going into my 7th season with the Colts. I was born, raised and graduated a Hoosier.
                  Away from work I chase my toddler around, enjoy running and spending time with friends and 
                  family at the lake.
                </div>
              </div>
            </div>
            <button @click="hannah = !hannah">
              <p v-if="!hannah">More Info</p>
              <p v-else>Close</p>
            </button>
          </div>
        </div>
        <div class="group">
          <div class="team__member">
            <img src="@/assets/PremiumTeam/Tyson-Smith.png" alt="Tyson Smith">
            <h3>Tyson Smith</h3>
            <div class="spacer"></div>
            <div class="info tyson" :class="{ active: tyson }">
              <div class="info-content" :class="{ active: !tyson }">
                <p>Suite Sales Manager</p>
                <a href="mailto:Tyson.Smith@colts.nfl.net">Tyson.Smith@colts.nfl.net</a>
                <a href="tel:317-808-5152">317-808-5152</a>
              </div>
              <div class="info-content" :class="{ active: tyson }">
                <div class="information">
                  I am going into my 4th season with the Colts, originally from northwest Ohio and
                  graduated from the University of Mount Union. Outside of game days, I enjoy attending
                  baseball games and spending time with my wife and three kids.
                </div>
              </div>
            </div>
            <button @click="tyson = !tyson">
              <p v-if="!tyson">More Info</p>
              <p v-else>Close</p>
            </button>
          </div>
          <div class="team__member">
            <img src="@/assets/PremiumTeam/Jody-Henton.png" alt="jody henton">
            <h3>Jody Henton</h3>
            <div class="spacer"></div>
            <div class="info jody" :class="{ active: jody }">
              <div class="info-content" :class="{ active: !jody }">
                <p>Suite Services Manager</p>
                <a href="mailto:Jody.Henton@colts.nfl.net">Jody.Henton@colts.nfl.net</a>
                <a href="tel:317-808-5176">317-808-5176</a>
              </div>
              <div class="info-content" :class="{ active: jody }">
                <div class="information">
                  I am going into my 8th season with the Colts, originally from Iowa and graduated from
                  the University of Iowa in 2014. My favorite current Colts player is Quenton Nelson.
                  Outside of game days, I enjoy playing golf, bike rides with my wife and spending time
                  with family and friends!
                </div>
              </div>
            </div>
            <button @click="jody = !jody">
              <p v-if="!jody">More Info</p>
              <p v-else>Close</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "PremiumTeam",
    data() {
      return {
        justin: false,
        hannah: false,
        tyson: false,
        jody: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  #PremiumTeam {
    position: relative;
    background-image: url("~@/assets/PremiumTeam/premium-team-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url("~@/assets/PremiumTeam/premium-team-mobile-bg-small.jpg");
      padding: 70px 30px 70px 20px;
    }
    #PremiumTeamMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      padding: 110px 10% 124px 17.8%;
      @media (max-width: $lg) {
        padding: 0;
      }
    }
    @media (max-width: $lg) {
      padding: 70px 30px 70px 20px;
    }
    .title {
      // margin-bottom: 88px;
      @media (max-width: $lg) {
        // margin-bottom: 49px;
        // width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: StampedeCondensed-Bold;
        font-size: 80px;
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke: 2px #FFFFFF;
        letter-spacing: 0;
        line-height: 68px;
        @media (max-width: $lg) {
          font-size: 50px;
          line-height: 45px;
        }
        @media (max-width: 374px) {
          font-size: 40px;
          line-height: 35px;
        }
      }
      img {
        margin: -24px 0 0 68px;
        @media (max-width: $lg) {
          width: 238px;
          margin: -12px 0 0 21px;
        }
      }
      p {
        font-family: Industry-Bold;
        font-size: 14px;
        color: #FFFFFE;
        letter-spacing: 0;
        text-align: left;
        line-height: 19px;
        margin: 41px 0 60px 0;
      }
    }
    .team {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: $xl) {
        flex-direction: column;
      }
      @media (max-width: $lg) {
        flex-direction: column;
        align-items: center;
      }
      .group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(50% - 31px);
        @media (max-width: $xl) {
          width: 100%;
          &:first-child {
            margin-bottom: 40px;
          }
        }
        @media (max-width: $lg) {
          width: 80%;
          justify-content: space-around;
        }
        @media (max-width: $md) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

      }
      &__member {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 31px);
        @media (max-width: $lg) {
          width: 200px;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        h3 {
          font-family: Stampede-Regular;
          font-size: 22px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 22px;
          margin: 25px 0 12px 0;
          @media (max-width: 1300px) {
            font-size: 20px;
            line-height: 20px;
          }
          @media (max-width: $xl) {
            font-size: 22px;
            line-height: 22px;
          }
          @media (max-width: $lg) {
            font-size: 22px;
            line-height: 22px;
            white-space: nowrap;
          }
        }
        .spacer {
          height: 4px;
          width: 100%;
          max-width: 200px;
          background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
          margin-bottom: 12px;
        }
        .info {
          position: relative;
          display: flex;
          flex-direction: column;
          height: 72px;
          max-height: 72px;
          width: 100%;
          &.justin {
            &.active {
              height: 320px;
              max-height: 320px;
              @media (max-width: 1500px) {
                height: 230px;
              }
              @media (max-width: $xl) {
                height: 320px;
              }
              @media (max-width: $lg) {
                
              }
            }
          }
          &.hannah {
            &.active {
              height: 165px;
              max-height: 165px;
              @media (max-width: 1500px) {
                height: 130px;
              }
              @media (max-width: $xl) {
                height: 165px;
              }
            }
          }
          &.tyson {
            &.active {
              height: 190px;
              max-height: 190px;
              @media (max-width: 1500px) {
                height: 150px;
              }
              @media (max-width: $xl) {
                height: 185px;
              }
            }
          }
          &.jody {
            &.active {
              height: 215px;
              max-height: 215px;
              @media (max-width: 1500px) {
                height: 175px;
              }
              @media (max-width: $xl) {
                height: 215px;
              }
            }
          }
          &-content {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            width: 220px;
            // transition: opacity 1s linear;
            &.active {
              // opacity: 1;
              // transition: max-height 1s linear, opacity 1s linear;
              p, a, .information {
                opacity: 1;
                // transition: opacity 0.1s linear 0.1;
              }
            }
              p, a {
              font-family: Industry-Demi;
              font-size: 14px;
              color: #FFFFFF;
              letter-spacing: 0;
              text-align: center;
              line-height: 26px;
              text-decoration: none;
              white-space: nowrap;
              opacity: 0;
              // transition: opacity 0.1s linear 0.1s;
              @media (max-width: 1500px) {
                font-size: 12px;
                line-height: 24px;
              }
              @media (max-width: $xl) {
                font-size: 14px;
                line-height: 26px;
              }
              @media (max-width: $lg) {
                font-size: 14px;
                line-height: 26px;
              }
            }
            .information {
              font-family: Industry-Demi;
              font-size: 14px;
              color: #FFFFFF;
              letter-spacing: 0;
              text-align: center;
              line-height: 26px;
              height: fit-content;
              min-height: fit-content;
              opacity: 0;
              // transition: max-height 1s linear, opacity 1s linear;
              @media (max-width: 1500px) {
                font-size: 12px;
                line-height: 24px;
              }
              @media (max-width: $xl) {
                font-size: 14px;
                line-height: 26px;
              }
              @media (max-width: $lg) {
                font-size: 14px;
                line-height: 26px;
              }
            }
          }
          // p, a {
          //   font-family: Industry-Demi;
          //   font-size: 14px;
          //   color: #FFFFFF;
          //   letter-spacing: 0;
          //   text-align: center;
          //   line-height: 26px;
          //   text-decoration: none;
          //   white-space: nowrap;
          //   @media (max-width: 1500px) {
          //     font-size: 12px;
          //     line-height: 24px;
          //   }
          //   @media (max-width: $xl) {
          //     font-size: 14px;
          //     line-height: 26px;
          //   }
          //   @media (max-width: $lg) {
          //     font-size: 14px;
          //     line-height: 26px;
          //   }
          // }
          // span {
          //   font-family: Industry-Demi;
          //   font-size: 14px;
          //   color: #FFFFFF;
          //   letter-spacing: 0;
          //   text-align: center;
          //   line-height: 26px;
          //   // height: 0;
          //   @media (max-width: 1500px) {
          //     font-size: 12px;
          //     line-height: 24px;
          //   }
          //   @media (max-width: $xl) {
          //     font-size: 14px;
          //     line-height: 26px;
          //   }
          //   @media (max-width: $lg) {
          //     font-size: 14px;
          //     line-height: 26px;
          //   }
          // }
        }
        button {
          margin-top: 29px;
          width: 146px;
          height: 40px;
          border: 2px solid #006fda;
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.10);
          background-color: transparent;
          font-family: StampedeCondensed-Bold;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          text-transform: uppercase;
          outline: none;
          z-index: 3;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: #006FDA;
            box-shadow: 0 0 4px 4px rgba(101,177,251,0.20);
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>