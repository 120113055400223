import axios from "axios";

const state = {
  userInfo: null,
  loadedItems: {
    images: false,
    video: false,
    userInfo: false,
    fonts: false,
  },
  allUserAccs: [],
  isMobile: false,
  formInfo: null,
};

const getters = {
  getUserInfo: (state) => state.userInfo,
};

const actions = {
  async fetchUserInfo({ commit }, data) {
    commit("setUserInfo", data);
    commit("setLoadedItem", {
      loadedProperty: "userInfo",
      isLoaded: true,
    });
  },
  async tryCookie({ commit }, payload) {
    const response = await axios.post(
      process.env.VUE_APP_API_LOCATION + "/api/router/Users.php",
      {
        action: "tryCookie",
      }
    );

    commit("setUserInfo", response ? response.data : "fetchUserInfoFailed");
    commit("setLoadedItem", {
      loadedProperty: "userInfo",
      isLoaded: true,
    });
  },
  async logout({ commit }, payload) {
    const response = await axios.post(
      process.env.VUE_APP_API_LOCATION + "/api/router/Users.php",
      {
        action: "logout",
      }
    );

    commit("logout");
  },
  async setLoadedItem({ commit }, payload) {
    commit("setLoadedItem", payload);
  },
  async setUserInfoObject({ commit }, payload) {
    commit("setUserInfoObject", payload);
  },
  async setIsMobile({ commit }, payload) {
    commit("setIsMobile", payload);
  },
  async setFormInfo({ commit }, payload) {
    commit("setFormInfo", payload);
  },
};

const mutations = {
  setUserInfo: (state, payload) => {
    if (!payload.output || !payload.output.userData) return;

    state.userInfo = payload.output.userData;
    state.formInfo = payload.output.formInfo;
  },
  setUserInfoObject: (state, payload) => {
    if (!payload) return;
    state.userInfo = payload;
  },
  setLoadedItem: (state, payload) => {
    state.loadedItems = {
      ...state.loadedItems,
      [payload.loadedProperty]: payload.isLoaded,
    };
  },
  setIsMobile: (state, payload) => {
    state.isMobile = payload;
  },
  logout: (state, payload) => {
    state.userInfo = null;
    state.formInfo = null;
  },
  setFormInfo: (state, payload) => {
    const oldFormInfoCopy = state.formInfo.find(
      (x) => x.formId === payload.formId
    );
    const formsCopy = state.formInfo.filter((x) => x.formId !== payload.formId);
    if (oldFormInfoCopy !== undefined) {
      oldFormInfoCopy.formValues = payload.formValues;
      formsCopy.push(oldFormInfoCopy);
    }
    state.formInfo = formsCopy;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
