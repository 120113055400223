import { render, staticRenderFns } from "./MapBig2.vue?vue&type=template&id=5c36022e&scoped=true&"
import script from "./MapBig2.vue?vue&type=script&lang=js&"
export * from "./MapBig2.vue?vue&type=script&lang=js&"
import style0 from "./MapBig2.vue?vue&type=style&index=0&id=5c36022e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c36022e",
  null
  
)

export default component.exports