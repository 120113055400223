<template>
  <section id="Schedule">
    <div id="ScheduleMarker"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
      <div class="title">
        <h1>Team</h1>
        <h2>Schedule</h2>
      </div>
      <div class="contentRow row1">
        <div class="header">
          <div class="rectangle"></div>
          <h3>Home</h3>
          <div class="rectangle"></div>
        </div>
        <div class="logoRow home">
          <div class="group group1">
            <div class="logo">
              <img src="@/assets/Schedule/Panthers.svg" alt="seattle seahawks">
              <p>08/15/2021<br>1:00pm</p>
              <p>*Preseason</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Seattle-Seahawks-logo.svg" alt="seattle seahawks">
              <p>09/12/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/LA-Rams-logo.svg" alt="LA rams">
              <p>09/19/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Houston-Texan-logo.svg" alt="houston texans">
              <p>10/17/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Tennessee-Titans-logo.svg" alt="tennessee titans">
              <p>10/31/2021<br>1:00pm</p>
            </div>
          </div>
          <div class="group group2">
            <div class="logo">
              <img src="@/assets/Schedule/New-York-Jets-logo.svg" alt="new york jets">
              <p>11/04/2021<br>8:20pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Jacksonville-Jaguars-logo.svg" alt="jacksonville jaguars">
              <p>11/14/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Tampa-Bay-logo.svg" alt="Tampa Bay Buccaneers">
              <p>11/28/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/New-England-Patriots-logo.svg" alt="new england patriots">
              <p>12/18 or 19/2021<br>TBD</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Raiders-logo.svg" alt="las vegas raiders">
              <p>01/02/2022<br>1:00pm</p>
            </div>
          </div>
        </div>
      </div>
      <div class="contentRow row2">
        <div class="header">
          <div class="rectangle"></div>
          <h3>Away</h3>
          <div class="rectangle"></div>
        </div>
        <div class="logoRow">
          <div class="group group1">
            <div class="logo">
              <img src="@/assets/Schedule/Vikings-logo.svg" alt="tennessee titans">
              <p>09/21/2021<br>8:00pm</p>
              <p>*Preseason</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Detroit-Lions-logo.svg" alt="tennessee titans">
              <p>08/27/2021<br>7:00pm</p>
              <p>*Preseason</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Tennessee-Titans-logo.svg" alt="tennessee titans">
              <p>09/26/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Miami-Dolphins-logo.svg" alt="new england patriots">
              <p>10/03/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Baltimore-Ravens-logo.svg" alt="las vegas raiders">
              <p>10/11/2021<br>8:15pm</p>
            </div>
          </div>
          <div class="group group2">
            <div class="logo">
              <img src="@/assets/Schedule/San-Francisco-49ers-logo.svg" alt="LA rams">
              <p>10/24/2021<br>8:20pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Buffalo-Bills-logo.svg" alt="new york jets">
              <p>11/21/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Houston-Texan-logo.svg" alt="houston texans">
              <p>12/05/2021<br>1:00pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Arizona-Cardinals-logo.svg" alt="seattle seahawks">
              <p>12/25/2021<br>8:15pm</p>
            </div>
            <div class="logo">
              <img src="@/assets/Schedule/Jacksonville-Jaguars-logo.svg" alt="jacksonville jaguars">
              <p>01/09/2022<br>1:00pm</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Schedule"
  }
</script>

<style lang="scss" scoped>
  #Schedule {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: url('~@/assets/Schedule/white-textured-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 105px 0 101px 160px;
    height: fit-content;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url('~@/assets/Benefits/benefits-mobile-bg.jpg');
      padding: 71px 20px;
    }
    #ScheduleMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      height: fit-content;
      min-height: fit-content;
      @media (max-width: $lg) {
        width: 100%;
        align-items: flex-start;
        height: fit-content;
        min-height: fit-content;
      }
      .title {
        margin-bottom: 59px;
        @media (max-width: $lg) {
          margin-bottom: 41px;
        }
        h1 {
          font-family: StampedeCondensed-Bold;
          font-size: 80px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #023460;
          letter-spacing: 0;
          line-height: 68px;
          @media (max-width: $lg) {
            font-size: 60px;
            line-height: 50px;
          }
          @media (max-width: 374px) {
            font-size: 48px;
            line-height: 39px;
          }
        }
        h2 {
          font-family: StampedeCondensed-Bold;
          font-size: 55px;
          color: #023460;
          letter-spacing: 0;
          line-height: 52px;
          margin: -21px 0 0 81px;
          @media (max-width: $lg) {
            font-size: 40px;
            line-height: 45px;
            margin: -16px 0 0 35px;
          }
        }
      }
      .contentRow {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        min-height: fit-content;
        margin: 0;
        // @media (max-width: $md) {
        //   height: fit-content;
        // }
        &.row2 {
          margin-top: 34px;
          @media (max-width: $lg) {
            margin-top: 40px;
          }
        }
        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          h3 {
            font-family: Stampede-Regular;
            font-size: 16px;
            color: #AAAFAF;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            width: 168px;
          }
          .rectangle {
            height: 1px;
            width: calc(50% - 84px);
            background: #D1D1D1;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
          }
        }
        .logoRow {
          position: relative;
          display: flex;
          // flex-direction: row;
          flex-direction: column;
          height: fit-content;
          min-height: fit-content;
          // @media (max-width: $md) {
            // height: calc(100vw / 2);
          // }
          // &.home {
            .group1 {
              margin-bottom: 6px;
              // width: calc((100% / 9) * 4);
              // width: 100%;
              // @media (max-width: $md) {
              //   width: 100%;
              // }
              @media (max-width: 450px) {
                // flex-wrap: wrap;
                margin-bottom: 0;
              }
            }
          //   .group2 {
          //     // width: calc((100% / 9) * 5);
          //     width: 100%;
          //     // @media (max-width: $md) {
          //     //   width: 100%;
          //     // }
          //     @media (max-width: 450px) {
          //       flex-wrap: wrap;
          //     }
          //   }
          // }
          .group {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            // width: 50%;
            width: 100%;
            height: fit-content;
            @media (max-width: $md) {
              width: 100%;
            }
            @media (max-width: 450px) {
              flex-wrap: wrap;
              justify-content: center;
            }
          }
          .logo {
            height: fit-content;
            width: 20%;
            max-width: 100px;
            @media (max-width: 450px) {
              width: 50%;
              padding: 0 10px;
            }
            img {
              width: 100%;
              object-fit: contain;
            }
            p {
              font-family: Industry-Bold;
              font-size: 10px;
              color: #1D252C;
              letter-spacing: -0.25px;
              text-align: center;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
</style>