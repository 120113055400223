<template>
  <section id="AccountDetails">
    <div id="AccountDetailsMarker"></div>
    <div class="brick"></div>
    <div 
      class="content1" v-if="map1"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-duration="1000"
    >
      <div class="left">
        <div class="title">
          <h1>Suite<br>Member</h1>
          <img src="@/assets/AccountDetails/AccountDetails.png">
        </div>
        <div class="table">
          <div class="detailsRow">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1">Suite #</div>
              <div class="column column2">{{ global.userInfo.suiteNum }}</div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Account ID #</div>
                <div class="column column2">{{ global.userInfo.accountId }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Company Name</div>
                <div class="column column2">{{ global.userInfo.companyName }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Primary Decision Maker</div>
                <div class="column column2">{{ global.userInfo.primaryDecisionMaker }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Day-to-Day Contact</div>
                <div class="column column2">{{ global.userInfo.dayDayContact }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Renewal Year</div>
                <div class="column column2">{{ global.userInfo.renewalYear }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1"># of Suite Tickets</div>
                <div class="column column2">{{ global.userInfo.qtySuiteTickets }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1"># of Parking Passes</div>
                <div class="column column2">{{ global.userInfo.qtyParkingPasses }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1"># of Guest Passes</div>
                <div class="column column2">{{ global.userInfo.qtyGuestPasses }}</div>
              </div>
            </div>
            <div class="detailsRow">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1"># of Host Passes</div>
                <div class="column column2">{{ global.userInfo.qtyHostPasses }}</div>
              </div>
              <div class="rectangle"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <Map1 v-if="map1" /> -->
        <Map1 />
      </div>
    </div>
    <div class="content2"
      v-if="map2 || map3"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-duration="1000"
    >
      <div class="title">
        <h1>Suite<br>Member</h1>
        <img src="@/assets/AccountDetails/AccountDetails.png">
      </div>
      <div class="table">
        <div class="left">
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1">Suite #</div>
              <div class="column column2">{{ global.userInfo.suiteNum }}</div>
            </div>
            <div class="row">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Account ID #</div>
                <div class="column column2">{{ global.userInfo.accountId }}</div>
              </div>
            </div>
            <div class="row">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Company Name</div>
                <div class="column column2" >{{ global.userInfo.companyName }}</div>
              </div>
            </div>
            <div class="row">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Primary Decision Maker</div>
                <div class="column column2">{{ global.userInfo.primaryDecisionMaker }}</div>
              </div>
            </div>
            <div class="row">
              <div class="rectangle"></div>
              <div class="info">
                <div class="column column1">Day-to-Day Contact</div>
                <div class="column column2">{{ global.userInfo.dayDayContact }}</div>
              </div>
              <div class="rectangle mobile"></div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1">Renewal Year</div>
              <div class="column column2">{{ global.userInfo.renewalYear }}</div>
            </div>
          </div>
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1"># of Suite Ticket</div>
              <div class="column column2">{{ global.userInfo.qtySuiteTickets }}</div>
            </div>
          </div>
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1"># of Parking Passes</div>
              <div class="column column2">{{ global.userInfo.qtyParkingPasses }}</div>
            </div>
          </div>
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1"># of Guest Passes</div>
              <div class="column column2">{{ global.userInfo.qtyGuestPasses }}</div>
            </div>
          </div>
          <div class="row">
            <div class="rectangle"></div>
            <div class="info">
              <div class="column column1"># of Host Passes</div>
              <div class="column column2">{{ global.userInfo.qtyHostPasses }}</div>
            </div>
            <div class="rectangle"></div>
          </div>
        </div>
      </div>
      <MapBig2 />
      <!-- <Map3 v-if="map3" /> -->
    </div>
  </section>
</template>

<script>
  import Map1 from "@/components/sections/AccountDetails/Map1";
  // import Map2 from "@/components/sections/AccountDetails/Map2";
  // import MapBig from "@/components/sections/AccountDetails/MapBig";
  import MapBig2 from "@/components/sections/AccountDetails/MapBig2";
  import Map3 from "@/components/sections/AccountDetails/Map3";
  import { mapState } from "vuex";
  
  export default {
    name: "AccountDetails",
    components: {
      Map1,
      // Map2,
      // MapBig,
      MapBig2,
      Map3
    },
    computed: {
      ...mapState(["global"])
    },
    data() {
      return {
        map1: false,
        map2: false,
        map3: false
      }
    },
    mounted() {
      this.activeMap(this.global.userInfo.suiteNum.charAt(0))
    },
    methods: {
      activeMap(char) {
        if (char == "F") {
          this.map1 = true
        } else if (char == "U") {
          this.map2 = true
        } else if (char == "L") {
          this.map3 = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #AccountDetails {
    position: relative;
    background-image: url('~@/assets/AccountDetails/SUITE-MEMBER-BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 105px 10% 101px 17.8%;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url('~@/assets/AccountDetails/ACCOUNT-DETAILS-mobile-BG-small.jpg');
      padding: 70px 30px 70px 20px;
      height: fit-content;
    }
    #AccountDetailsMarker {
      position: absolute;
      height: 0;
      width: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content1 {
      display: flex;
      flex-direction: row;
      @media (max-width: $lg) {
        flex-direction: column;
        height: fit-content;
      }
      .left {
        display: flex;
        flex-direction: column;
        width: 50%;
        @media (max-width: $lg) {
          width: 100%;
          margin-right: 0;
          align-items: center;
          height: fit-content;
        }
        .title {
          margin-bottom: 55.5px;
          h1 {
            font-family: StampedeCondensed-Bold;
            font-size: 80px;
            color: rgba(255, 255, 255, 0);
            -webkit-text-stroke: 2px #FFFFFF;
            letter-spacing: 0;
            line-height: 68px;
            @media (max-width: $lg) {
              font-size: 60px;
              line-height: 50px;
            }
            @media (max-width: 374px) {
              font-size: 48px;
              line-height: 39px;
            }
          }
          img {
            margin: -20px 0 0 36px;
            @media (max-width: $lg) {
              width: 350px;
              max-width: 90%;
              margin: -15px 0 0 10px;
            }
          }
        }
        .table {
          background: rgba(29,37,44,0.60);
          padding: 20px 18px;
          min-width: 450px;
          max-width: 500px;
          @media (max-width: $lg) {
            padding: 0;
            margin-bottom: 40px;
            min-width: auto;
          }
          .detailsRow {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
            .rectangle {
              height: 2px;
              width: 100%;
              background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
            }
            .info {
              display: flex;
              flex-direction: row;
              padding: 4px 0;
              font-family: Industry-Demi;
              font-size: 14px;
              color: #FFFFFE;
              letter-spacing: 0;
              min-height: 38px;
              @media (max-width:$lg) {
                height: fit-content;
                min-height: 32px;
                padding: 4px 10px;
                align-items: center;
                font-size: 12px;
                line-height: 19px;
              }
              .column {
                height: 100%;
                padding: 0;
                @media (max-width: $lg) {
                  height: fit-content;
                }
                &.column1 {
                  width: 180px;
                  @media (max-width: $lg) {
                    width: 40%;
                    padding-right: 10px;
                  }
                  @media (max-width: 450px) {
                    width: 50%;
                  }
                }
                &.column2 {
                  width: calc(100% - 180px);
                  @media (max-width: 450px) {
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: center;
        width: 50%;
        padding-left: 30px;
        @media (max-width: $lg) {
          padding-left: 0;
          width: 100%;
          max-height: 480px;
          svg {
            height: 100%;
            max-height: 480px;
            object-fit: contain;
          }
        }
      }
    }

    .content2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        width: 100%;
        margin-bottom: 58.5px;
        h1 {
          font-family: StampedeCondensed-Bold;
          font-size: 80px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #FFFFFF;
          letter-spacing: 0;
          line-height: 68px;
          @media (max-width: $lg) {
            font-size: 48px;
            line-height: 39px;
          }
        }
        img {
          margin: -20px 0 0 36px;
        }
      }
      .table {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: rgba(29,37,44,0.60);
        padding: 20px 18px;
        margin-bottom: 80px;
        @media (max-width: $lg) {
          padding: 0;
          margin-bottom: 40px;
          flex-direction: column;
          min-width: 450px;
          max-width: 500px;
        }
        .left {
          width: calc(50% - 18px);
          @media (max-width: 1230px) {
            width: calc(100% - 426px);
          }
          @media (max-width: $lg) {
            width: 100%;
          }
        }
        .right {
          width: calc(50% - 18px);
          min-width: 408px;
          @media (max-width: $lg) {
            width: 100%;

          }
        }
        .row {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          @media (max-width: $lg) {
            min-height: 32px;
          }
          .rectangle {
            height: 2px;
            width: 100%;
            background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
            &.mobile {
              @media (max-width: $lg) {
                display: none;
              }
            }
          }
          .info {
            display: flex;
            flex-direction: row;
            padding: 4px 0;
            font-family: Industry-Demi;
            font-size: 14px;
            color: #FFFFFE;
            letter-spacing: 0;
            min-height: 38px;
            @media (max-width:$lg) {
              height: fit-content;
              min-height: 32px;
              padding: 4px 10px;
              align-items: center;
              line-height: 19px;
            }
            .column {
              height: 100%;
              padding: 0;
              @media (max-width: $lg) {
                height: fit-content;
              }
              &.column1 {
                width: 180px;
              }
              &.column2 {
                  width: calc(100% - 180px)
              }
            }
          }
        }
      }
    }
  }
</style>