<template>
  <section id="FaqAccordion">
    <b-card
      no-body
      v-for="(item, index) in accordionData"
      :key="'item' + index"
      v-b-toggle="'accordion-' + uniquePrefix + index"
    >
      <b-card-header>
        <h1>{{ item.title }}</h1>
        <b-button>
          <p class="expand">Expand</p>
          <p class="not-expanded">Close</p>
          <svg width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#FFF" fill-rule="evenodd"><path d="M13.205 19.572l8.161-8.137-2.07-2.063-6.088 6.07-6.097-6.079-2.07 2.064 6.098 6.079-.004.003z"/><path d="M26.288 13.47C26.288 6.307 20.464.5 13.279.5 6.094.5.269 6.307.269 13.47c0 7.164 5.825 12.97 13.01 12.97s13.009-5.806 13.009-12.97m-1.585 0c0 3.043-1.189 5.903-3.346 8.054a11.367 11.367 0 01-8.078 3.336c-3.052 0-5.92-1.184-8.078-3.336a11.297 11.297 0 01-3.346-8.054c0-3.042 1.188-5.902 3.346-8.053A11.366 11.366 0 0113.28 2.08c3.051 0 5.92 1.184 8.078 3.336a11.298 11.298 0 013.346 8.053"/></g></svg>
        </b-button>
      </b-card-header>
      <b-collapse v-if="item.description" :id="'accordion-' + uniquePrefix + index" accordion="my-accordion">
        <p>{{ item.description }}</p>
      </b-collapse>
      <b-collapse v-if="item.points" :id="'accordion-' + uniquePrefix + index" accordion="my-accordion">
        <div class="accordion-top">
          <ul>
            <li v-for="(point, index) in item.points" :key="'point' + index">
              {{ point }}
            </li>
          </ul>
        </div>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
  export default {
    name: "FaqAccordion",
    props: {
      accordionData: {
        type: Array,
        required: true
      },
      uniquePrefix: {
        type: String,
        required: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  #FaqAccordion {
    .card {
      margin-top: 10px;
      border: none;
      border-radius: 0;
      outline: none;
      background: transparent;
      &.collapsed {
        .not-expanded {
          display: none;
        }
      }
      &.not-collapsed {
        svg {
          transform: rotate(180deg);
        }
        .expand {
          display: none;
        }
      }
      .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 11px 30px 15px 28px;
        background: rgba(29, 37, 44, 0.7);
        border: none;
        border-radius: 0;
        @media (max-width: $lg) {
          padding: 12px 16px 15px 15px;
          background: rgba(29,37,44,0.80);
        }
        h1 {
          font-family: Industry-Medium;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 22px;
          @media (max-width: $lg) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          svg {
            transition: transform 0.3s ease-in-out;
            outline: none;
            margin-left: 12.7px;
            height: 26px;
            width: 27px;
            @media (max-width: $lg) {
              height: 16px;
              width: 16px;
              margin-left: 0;
            }
          }
          p {
            font-family: Industry-Medium;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: right;
            line-height: 20px;
            @media (max-width: $lg) {
              display: none;
            }
          }
        }
      }
      .collapse {
        display: flex;
        flex-direction: column;
        width: 100%;
        p {
          font-family: Industry-Medium;
          font-size: 14px;
          color: #FFFFFE;
          letter-spacing: 0;
          line-height: 23px;
          border: none;
          padding: 0 40px 29px 40px;
          background: rgba(29, 37, 44, 0.7);
          @media (max-width: $lg) {
            padding: 0 50px 37px 21px;
            background: rgba(29,37,44,0.80);
          }
        }
        .accordion-top {
          background: rgba(29, 37, 44, 0.7);
          padding-top: 28px;
          border-top: 1px solid #FFFFFF;
          @media (max-width: $lg) {
            background: rgba(29,37,44,0.80);
            border-top: 2px solid #FFFFFF;
            padding-top: 18px;
          }
          ul {
            padding: 0 40px 29px 40px;
            margin: 0;
            @media (max-width: $lg) {
              padding: 0 16px 18px 30px;
            }
            li {
              font-family: Industry-Medium;
              font-size: 14px;
              color: #FFFFFE;
              letter-spacing: 0;
              line-height: 23px;
              @media (max-width: $lg) {
                font-size: 12px;
                line-height: 24px;
              }
            }
          }
        }
        .accordion-bottom {
          position: relative;
          background: transparent;
          height: fit-content;

          @media (max-width: $lg) {
            height: fit-content;
          }
          &__title {
            height: 98px;
            width: 100%;
            background: rgba(29, 37, 44, 0.7);
            padding-top: 5px;
              font-family: Industry-Bold;
            p {
              font-size: 14px;
              color: #FFFFFF;
              letter-spacing: 0;
              text-align: center;
              line-height: 20px;
              background: rgba(29, 37, 44, 0.7);
              @media (max-width: $lg) {
                font-size: 12px;
              }
            }
          }
          ul {
            position: relative;
            list-style: none;
            display: flex;
            flex-direction: row;
            background: #F9F9F9;
            box-shadow: 0 2px 14px 0 rgba(0,0,0,0.20);
            margin: -65px 10px 0 10px;
            padding: 0;
            @media (max-width: $lg) {
              flex-direction: column;
              height: fit-content;
            }
            li {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              width: calc(100% / 3);
              background-image: linear-gradient(180deg,
                rgba(163,169,172,0.00) 0%,
                rgba(163,169,172,0.13) 99%
              );
              padding: 20px 10px 17px 10px;
              margin: 10px;
              @media (max-width: $lg) {
                width: calc(100% - 20px);
                padding: 18px 10px 15px 10px;
              }
              &:nth-child(2) {
                margin: 10px 0;
                @media (max-width: $lg) {
                  margin: 0 10px;
                }
              }
              h4 {
                font-family: Stampede-Regular;
                font-size: 24px;
                color: #023460;
                letter-spacing: 0;
                text-align: center;
                line-height: 24px;
                margin-bottom: 8px;
                @media (max-width: $lg) {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              span {
                width: 80%;
                font-family: Industry-Medium;
                font-size: 14px;
                color: #1D252C;
                letter-spacing: 0;
                text-align: center;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
</style>