<template>
  <section id="Landing">
    <div id="LandingMarker"></div>
    <div class="brick"></div>
    <a
      href="https://am.ticketmaster.com/colts/#/"
      target="_blank"
      class="account-manager"
    >
      Colts Account<br>Manager
    </a>
    <img class="player" src="@/assets/Landing/Buckner-cutout-small.png" alt="buckner">
    <div class="content">
      <div class="lines top" :class="{ active: topLines }"  data-aos="fade-down" data-aos-delay="500" data-aos-duration="1000">
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="middle">
        <p v-if="global.userInfo">Welcome {{ global.userInfo.companyName }}</p>
        <p v-else>Welcome Suite Member</p>
        <div class="middle__line"></div>
        <img src="@/assets/Landing/colts-suite-membership.png" alt="indianapolis colts suite membership">
        <span>
          For&nbsp;the&nbsp;Experience. For&nbsp;the&nbsp;Excitement.
          <br class="mobile-break">For&nbsp;the&nbsp;Shoe.
        </span>
      </div>
      <div class="lines bottom" :class="{ active: bottomLines }" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <div class="bottom__mobile">
      <div class="mobile-lines">
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <img src="@/assets/Landing/Buckner-cutout-small.png" alt="buckner">
    </div>
    <div class="seperator"></div>
  </section>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "Landing",
    computed: {
      ...mapState(["global"])
    },
    data() {
      return {
        topLines: false,
        bottomLines: false
      }
    },
    mounted() {
      this.topLines = true;
      this.bottomLines = true;
    }
  }
</script>

<style lang="scss" scoped>
  #Landing {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 90vh;
    max-height: 900px;
    background-image: url('~@/assets/Landing/HOME-BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 0 125px 0 160px;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $xl) {
      // padding-right: 80px;
      padding: 0 30px 0 20px;
    }
    @media (max-width: $lg) {
      // background-image: url('~@/assets/Landing/landing-mobile-bg.jpg');
      background-image: url('~@/assets/Landing/hero-mobile-bg.jpg');
      margin-top: 80px;
      flex-direction: column;
      padding: 51px 0 0 0;
    }
    #LandingMarker {
      position: absolute;
      height: 0;
      width: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .account-manager {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 27px;
      right: 9.3125%;
      width: 133px;
      height: 46px;
      border: 2px solid #006fda;
      background: transparent;
      font-family: StampedeCondensed-Bold;
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 15px;
      text-decoration: none;
      z-index: 3;
      outline: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #006FDA;
        box-shadow: 0 0 4px 4px rgba(101,177,251,0.20);
        color: #FFFFFF;
      }
      @media (max-width: $lg) {
        display: none;
      }
    }
    .player {
      position: absolute;
      bottom: 0;
      left: 25%;
      height: 90%;
      z-index: 2;
      @media (max-width: 1450px) {
        left: 20%;
      }
      @media (max-width: 1300px) {
        left: 15%;
      }
      @media (max-width: $lg) {
        display: none;
      }
    }
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 21px);
      margin-bottom: 21px;
      // padding: 0 31px;
      @media (max-width: $lg) {
        height: 30%;
        padding: 0;
      }
      .top {
        height: 15%;
        @media (max-width: 1350px) {
          height: 10%;
        }
        @media (max-width: $lg) {
          display: none;
        }
        .line {
          height: 100%;
          // height: 0;
          // transition: height linear 0.3s;
          // transition-delay: 0.5s;
        }
        // &.active {
        //   .line {
        //     // height: 100%;
        //   }
        // }
        
      }
      .bottom {
        height: 33%;
        @media (max-width: 1350px) {
          height: 27%;
        }
        @media (max-width: $lg) {
          display: none;
        }
        .line {
          height: 100%;
          // height: 0;
          // transition: height linear 0.4s;
          // transition-delay: 1s;
        }
        &.active {
          .line {
            height: 100%;
          }
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: $lg) {
          width: 650px;
        }
        p {
          font-family: StampedeCondensed-Bold;
          font-size: 35px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 35px;
          padding-bottom: 15px;
          @media (max-width: $xl) {
            font-size: 30px;
            line-height: 30px;
          }
          @media (max-width: $lg) {
            font-size: 25px;
            line-height: 25px;
          }
        }
        &__line {
          height: 4px;
          width: 161px;
          margin: 3px 0 22px 0;
          background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
          @media (max-width: $lg) {
            margin: 3px 0 14px 0;
          }
        }
        img {
          @media (max-width: $xl) {
            width: 85%;
          }
        }
        span {
          font-family: Industry-Demi;
          font-size: 19px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          margin-top: 32px;
          text-transform: uppercase;
          @media (max-width: $xl) {
            font-size: 15px;
            line-height: 16px;
          }
          @media (max-width: $lg) {
            padding: 0 30px;
            .mobile-break {
              display: none;
            }
          }
          @media (max-width: 374px) {
            padding: 0 0 20px 0;
          }
        }
      }
    }
    .bottom__mobile {
      position: relative;
      display: flex;
      justify-content: center;
      height: 70%;
      z-index: 2;
      img {
        height: 100%;
        z-index: 2;
      }
      .mobile-lines {
        position: absolute;
        top: 30%;
        left: 0;
        width: 50%;
        left: 0;
      }
      @media (min-width: $lg) {
        display: none;
      }
    }
    .seperator {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 21px;
      width: 100%;
      background-image: url('~@/assets/Landing/white-textured-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      @media (min-width: $lg) {
        background-attachment: fixed;
      }
    }
  }
</style>