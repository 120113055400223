<template>
  <div id="mainPage">
    <SideNav v-if="windowWidth > 992" key="1" />
    <MobileNav v-else key="2" />
    <Landing />
    <AccountDetails />
    <div class="seperator"></div>
    <GetToKnowYou />
    <Benefits />
    <!-- <ComingSoon /> -->
    <PremiumTeam />
    <Schedule />
    <ColtsGameDay />
    <Events />
    <Faq />
  </div>
</template>

<script>
  import SideNav from "@/components/Layout/SideNav";
  import MobileNav from "@/components/Layout/MobileNav"
  import Landing from "@/components/sections/Landing/Landing";
  import AccountDetails from "@/components/sections/AccountDetails/AccountDetails";
  import Benefits from "@/components/sections/Benefits/Benefits";
  import ComingSoon from "@/components/sections/Benefits/ComingSoon"
  import GetToKnowYou from "@/components/sections/GetToKnowYou/GetToKnowYou";
  import PremiumTeam from "@/components/sections/PremiumTeam/PremiumTeam";
  import Schedule from "@/components/sections/Schedule/Schedule";
  import ColtsGameDay from "@/components/sections/ColtsGameDay/ColtsGameDay";
  import Events from "@/components/sections/Events/Events";
  import Faq from "@/components/sections/Faq/Faq";

  export default {
    name: "mainPage",
    components: {
      SideNav,
      MobileNav,
      Landing,
      AccountDetails,
      Benefits,
      ComingSoon,
      GetToKnowYou,
      PremiumTeam,
      Schedule,
      ColtsGameDay,
      Events,
      Faq,
    },
    data() {
      return {
        windowWidth: window.innerWidth
      }
    },
    mounted() {
      let that = this
      window.addEventListener(
        "resize",
        () => {
          that.windowWidth = window.innerWidth
        },
        false
      );
    }
  }
</script>

<style lang="scss" scoped>
  #mainPage {
    .seperator {
      height: 45px;
      width: 100%;
      background-image: url('~@/assets/Landing/white-textured-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 3;
      @media (min-width: $lg) {
        background-attachment: fixed;
      }
    }
  }
</style>