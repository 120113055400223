<template>
  <div>
    <transition
      name="fade"
      v-if="
        !loaderFinished ||
        !global.loadedItems.images ||
        !global.loadedItems.fonts
      "
    >
      <SiteLoader @setLoaderFinished="setLoaderFinished" />
    </transition>
    <transition-group name="fade" v-else-if="firstMount">
      <!-- <SideNav v-if="windowWidth > 992" key="1" />
      <MobileNav v-else key="2" /> -->
      <div class="sections" key="2">
        <LoginPage v-if="!global.userInfo" />
        <router-view v-else />
        <!-- <router-view /> -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import SiteLoader from "@/components/global/SiteLoader";
import { mapActions, mapState } from "vuex";
import LoginPage from "@/components/sections/LoginPage/LoginPage";
import axios from "axios";
export default {
  name: "Layout",
  components: {
    SiteLoader,
    LoginPage,
  },
  computed: {
    ...mapState(["global"]),
  },
  data() {
    return {
      firstMount: false,
      loaderFinished: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.firstMount = true;
    this.setIsMobile(window.innerWidth < 992);

    let that = this;
    window.addEventListener(
      "resize",
      () => {
        that.windowWidth = window.innerWidth;
      },
      false
    );

    this.tryCookie();
    this.trackDeviceType();
  },
  methods: {
    setLoaderFinished() {
      this.loaderFinished = true;
    },
    trackDeviceType() {
      const label = window.innerWidth >= 992 ? "Desktop" : "Mobile";
      axios
        .post(process.env.VUE_APP_API_LOCATION + "/api/router/Tracking.php", {
          site: "common",
          action: "tracking",
          label,
          category: "Device Size",
        })
        .catch(() => {
          window.console.log("failed tracking");
        });
    },
    ...mapActions(["setIsMobile", "tryCookie"]),
  },
};
</script>

<style lang="scss" scoped>
/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
/* global desktop bricks */
.brick {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-image: url("~@/assets/Landing/long-brick.jpg");
  @media (min-width: $lg) {
    background-attachment: fixed;
  }
  @media (max-width: $lg) {
    width: 12px;
    background-image: url("~@/assets/Landing/Mobile-Brick.jpg");
  }
}
/* global lines */
.lines {
  display: flex;
  flex-direction: row;
  .line {
    height: 100%;
    width: 80px;
    background: #ffffff;
    &:first-child {
      margin-right: 35px;
    }
  }
}
.mobile-lines {
  display: flex;
  flex-direction: column;
  .line {
    height: 50px;
    width: 100%;
    background: #ffffff;
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
</style>