<template>
  <section id="ComingSoon">
    <div id="BenefitsMarker"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
      <div class="left">
        <div class="title">
          <h1>Suite<br>Benefits</h1>
          <!-- <img src="@/assets/Benefits/comingsoon.png" alt="coming soon"> -->
        </div>
        <a class="comingSoon-link" :href="global.userInfo.formstackLink" target="_blank">
          Select Your Benefits Package Now
        </a>
      </div>
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "ComingSoon",
    computed: {
      ...mapState(["global"])
    }
  }
</script>

<style lang="scss" scoped>
  #ComingSoon {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('~@/assets/Schedule/white-textured-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 110px 10% 124px 17.8%;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url('~@/assets/Benefits/benefits-mobile-bg.jpg');
      padding: 70px 30px 70px 20px;
    }
    #BenefitsMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 564px;
      width: 100%;
      background: #023460;
      box-shadow: 0 6px 30px 0 rgba(0,0,0,0.50);
      @media (max-width: $lg) {
        height: 300px;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          h1 {
            font-family: StampedeCondensed-Bold;
            font-size: 80px;
            color: rgba(255, 255, 255, 0);
            -webkit-text-stroke: 2px #FFFFFF;
            letter-spacing: 0;
            line-height: 68px;
            @media (max-width: $lg) {
              font-size: 60px;
              line-height: 50px;
            }
            @media (max-width: 374px) {
              font-size: 48px;
              line-height: 39px;
            }
          }
          img {
            margin: -18px 0 0 39px;
            @media (max-width: $lg) {
              width: 218px;
              max-width: 90%;
              margin: -10px 0 0 14px;
            }
          }
        }
        .comingSoon-link {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: StampedeCondensed-Bold;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          height: 50px;
          width: 185px;
          background: transparent;
          border: 2px solid #006fda;
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.10);
          margin-top: 45.8px;
          outline: none;
          text-decoration: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: #006FDA;
            box-shadow: 0 0 4px 4px rgba(101,177,251,0.20);
            color: #FFFFFF;
          }
        }
      }
      .lines {
        height: 100%;
        @media (max-width: $lg) {
          display: none;
        }
      }

    }
    
  }
</style>