var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"Events"}},[_c('div',{attrs:{"id":"EventsMarker"}}),_c('div',{staticClass:"content",attrs:{"data-aos":"fade-up","data-aos-duration":"1000","data-aos-offset":"200"}},[_vm._m(0),_c('span',[_vm._v(" Stay on top of all Colts Suite events, Colts game days and non-Colts events at Lucas Oil Stadium ")]),_c('div',{staticClass:"contentRow row1"},[_vm._m(1),(
          ((_vm.topCards.length < 4) && (_vm.windowWidth > 992)) ||
          ((_vm.topCards.length < 2) && (_vm.windowWidth <=992)) ||
          ((_vm.topCards.length < 2) && (_vm.windowWidth <= 650))
        )?_c('div',{staticClass:"cards cards1"},_vm._l((_vm.topCards),function(card,index){return _c('div',{key:'card' + index,staticClass:"card"},[_c('div',{staticClass:"top"},[(card.image)?_c('img',{attrs:{"src":require('@/assets/Events/' + card.image)}}):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('h4',{domProps:{"innerHTML":_vm._s(card.title)}}),_vm._l((card.dates),function(date,index){return _c('p',{key:'date' + index},[_vm._v(" "+_vm._s(date)+" ")])})],2)])}),0):_c('EventsCarousel',{attrs:{"data":_vm.topCards}})],1),(_vm.bottomCardsActive)?_c('div',{staticClass:"contentRow row2"},[_vm._m(2),(
          ((_vm.bottomCards.length < 4) && (_vm.windowWidth > 992)) || 
          ((_vm.bottomCards.length < 3) && (_vm.windowWidth <=992 && _vm.windowWidth > 650)) ||
          ((_vm.bottomCards.length < 2) && (_vm.windowWidth <= 650))
        )?_c('div',{staticClass:"cards cards2"},_vm._l((_vm.bottomCards),function(card,index){return _c('div',{key:'bottom' + index,staticClass:"card"},[_c('div',{staticClass:"top"},[(card.image)?_c('img',{attrs:{"src":require('@/assets/Events/' + card.image)}}):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('h4',{domProps:{"innerHTML":_vm._s(card.title)}}),_vm._l((card.dates),function(date,index){return _c('p',{key:'date' + index},[_vm._v(" "+_vm._s(date)+" ")])})],2)])}),0):_c('EventsCarousel',{attrs:{"data":_vm.bottomCards}})],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("Upcoming")]),_c('h2',[_vm._v("Events")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"rectangle"}),_c('h3',[_vm._v("Non-Colts Events")]),_c('div',{staticClass:"rectangle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"rectangle"}),_c('h3',[_vm._v("Suite Holder Events")]),_c('div',{staticClass:"rectangle"})])}]

export { render, staticRenderFns }