<template>
  <section id="Benefits">
    <div id="BenefitsMarker"></div>
    <div class="title">
      <h1>Suite</h1>
      <h2>Benefits</h2>
    </div>
    <div class="holder">
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="top">
        <div class="item" v-for="(benefit, index) in benefits" :key="'benefit' + index">
          {{ benefit.text }}
        </div>
        <!-- <div class="left">
          <template v-for="(option, index) in options">
            <div
              :key="'option' + index"
              class="option"
              :class="{ active: index === activeItem }"
              @click="selectItem(index)"
            >
              {{ option.title }}
              <img src="@/assets/Benefits/left-chevron.svg" alt="arrow">
              <div class="rectangle"></div>
            </div>
          </template>
        </div>
        <div class="right">
          <div class="card">
            <template v-for="(item, index) in options">
              <div
                :key="'card' + index"
                class="content"
                :class="{ active: index === activeItem }"
              >
                <h3 v-html="item.cardTitle"></h3>
                <p>{{ item.copy }}</p>
              </div>
            </template>
          </div>
        </div> -->
      </div>
      <!-- <div class="top-mobile">
        <MobileAccordion :accordionData="options" />
      </div> -->
      <div class="bottom">
        <b-carousel
          id="benefits-carousel"
          controls
          indicators
        >
          <!-- <b-carousel-slide
            v-for="(image, index) in galleryImages"
            :key="'image' + index"
          > -->
          <b-carousel-slide
            v-for="(image, index) in galleryImages"
            :key="'image' + index"
          >
            <template v-slot:img>
              <img :src="require('@/assets/Benefits/gallery/' + image)" alt="image slot">
            </template>
          </b-carousel-slide>
        </b-carousel>
        <!-- <vueVimeoPlayer
          ref="player"
          width="100%"
          :video-id="videoID"
          :options="videoOptions"
        ></vueVimeoPlayer> -->
      </div>
    </div>
  </section>
</template>

<script>
  import MobileAccordion from "@/components/sections/Benefits/MobileAccordion";
  import { vueVimeoPlayer } from "vue-vimeo-player";
  import { mapState } from "vuex";

  export default {
    name: "Benefits",
    components: {
      MobileAccordion,
      vueVimeoPlayer
    },
    computed: {
      ...mapState(["global"])
    },
    data() {
      return {
        activeItem: 0,
        videoID: "131229180",
        videoOptions: {
          background: false
        },
        benefits: [],
        // options: [
        //   {
        //     title: "Pre-Game Sideline Passes",
        //     cardTitle: "Pre-Game<br>Sideline<br>Passes",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Complimentary Dessert Cart",
        //     cardTitle: "Complimentary<br>Dessert<br>Cart",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Cheerleader Suite Visit",
        //     cardTitle: "Cheerleader<br>Suite<br>Visit",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Post-Game Field Access",
        //     cardTitle: "Post-Game<br>Field<br>Access",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Nulla tristique libero a pulvinar",
        //     cardTitle: "Post-Game<br>Field<br>Access",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Suspendisse ut arcu",
        //     cardTitle: "Post-Game<br>Field<br>Access",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Sed ac dui convallis turpis",
        //     cardTitle: "Post-Game<br>Field<br>Access",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   },
        //   {
        //     title: "Quisque eget risus ultrices",
        //     cardTitle: "Post-Game<br>Field<br>Access",
        //     copy: "Nam ac gravida eros, nec sagittis metus. Aliquam augue ante, tinciduntmagna et, convallis scelerisque nibh. In quis semper mi. Donec commodo leo leo, sed fringilla tellus suscipit sed vestib ulum id pulvinar. Sed a maximus lectus. Donec et mi tortor. Praesent vitae magna lacus.",
        //   }
        // ],
        galleryImages: [
          "TrainingCamp.jpg",
          "SuiteHolderLuncheon.jpg",
          "Luncheon.jpg",
          "Pre-Post-Field.jpg",
          "FlagExperiencePhoto.jpg",
          "Pre-Post-Field.jpg"
        ]
      }
    },
    created() {
      let benefits = this.global.userInfo.benefits;
      this.benefits = benefits;

      // benefits.forEach((benefit) => {
      //   switch(benefit.description) {
      //     case "K":
      //       this.galleryImages.push("TrainingCamp.jpg");
      //       break;
      //     case "M":
      //       this.galleryImages.push("SuiteHolderLuncheon.jpg");
      //       this.galleryImages.push("Luncheon.jpg");
      //       break;
      //     case "N":
      //       this.galleryImages.push("Pre-Post-Field.jpg");
      //       break;
      //     case "O":
      //       this.galleryImages.push("FlagExperiencePhoto.jpg")
      //       break;
      //     case "P":
      //       this.galleryImages.push("Pre-Post-Field.jpg");
      //       break;
      //   }
      // })
    },
    // methods: {
    //   selectItem(i) {
    //     this.activeItem = i;
    //   }
    // }
  }
</script>

<style lang="scss" scoped>
  #Benefits {
    position: relative;
    background-image: url('~@/assets/Benefits/white-textured-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 105px 10% 101px 17.8%;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url('~@/assets/Benefits/benefits-mobile-bg.jpg');
      padding: 66px 0 70px 0;
    }
    #BenefitsMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .title {
      margin-bottom: 57px;
      @media (max-width: $lg) {
        margin: 0 0 40px 17px;
      }
      h1 {
        font-family: StampedeCondensed-Bold;
        font-size: 80px;
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke: 2px #023460;
        letter-spacing: 0;
        line-height: 68px;
        @media (max-width: $lg) {
          font-size: 60px;
          line-height: 50px;
        }
        @media (max-width: 374px) {
          font-size: 48px;
          line-height: 39px;
        }
      }
      h2 {
        font-family: StampedeCondensed-Bold;
        font-size: 55px;
        color: #023460;
        letter-spacing: 0;
        line-height: 52px;
        margin: -16px 0 0 30px;
        @media (max-width: $lg) {
          font-size: 40px;
          margin: -22px 0 0 22px;
        }
      }
    }
    .holder {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #1D252C;
      box-shadow: 0 6px 30px 0 rgba(0,0,0,0.50);
      padding: 47px 39px 35px 35px;
      @media (max-width: $lg) {
        padding: 25px 30px 25px 20px;
      }
      .lines {
        position: absolute;
        top: 0;
        right: 125px;
        height: 100%;
        @media (max-width: $md) {
          right: 52px;
        }
        .line {
          @media (max-width: $lg) {
            width: 56px;
            &:first-child {
              margin-right: 24px;
            }
          }
        }
      }
      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        z-index: 2;
        height: fit-content;
        @media (max-width: $md) {
          flex-direction: column;
          flex-wrap: nowrap;
        }
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 75px;
          width: calc(50% - 10px);
          font-family: Stampede-Regular;
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 18px;
          background: rgba(0,0,0,0.85);
          margin-bottom: 7px;
          padding: 0 20px 0 28px;
          transition: background 0.3s ease-in-out;
          &:hover {
            background: #023460;
          }
          @media (max-width: $md) {
            width: 100%;
          }
        }
        // .left {
        //   display: flex;
        //   flex-direction: column;
        //   width: calc(45% - 10px);
        //   font-family: Stampede-Regular;
        //   font-size: 15px;
        //   color: #FFFFFF;
        //   letter-spacing: 0;
        //   line-height: 18px;
        //   .option {
        //     position: relative;
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     justify-content: space-between;
        //     background: rgba(0,0,0,0.60);
        //     padding: 0 20px 0 28px;
        //     height: 50px;
        //     margin-top: 7px;
        //     cursor: pointer;
        //     transition: all 0.3s ease-in-out;
        //     &:first-child {
        //       margin-top: 0;
        //     }
        //     img {
        //       transform: rotate(180deg);
        //       height: 13px;
        //     }
        //     .rectangle {
        //       position: absolute;
        //       bottom: 0;
        //       left: 0;
        //       width: 100%;
        //       height: 4px;
        //       background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
        //       box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
        //       opacity: 0;
        //       transition: opacity 0.3s ease-in-out;
        //     }
        //     &.active {
        //       background: #023460;
        //       .rectangle {
        //         opacity: 1;
        //       }
        //     }
        //   }
        // }
        // .right {
        //   width: calc(55% - 10px);
        //   min-height: 100%;
        //   .card {
        //     background: #023460;
        //     box-shadow: 0 6px 30px 0 rgba(0,0,0,0.50);
        //     height: 100%;
        //     .content {
        //       display: none;
        //       padding: 36px 31px;
        //       &.active {
        //         display: block;
        //       }
        //       h3 {
        //         font-family: StampedeCondensed-Bold;
        //         font-size: 46px;
        //         color: #FFFFFF;
        //         letter-spacing: 0.5px;
        //         line-height: 40px;
        //         margin-bottom: 11px;
        //       }
        //       p {
        //         font-family: Industry-Demi;
        //         font-size: 14px;
        //         color: #FFFFFE;
        //         letter-spacing: 0;
        //         line-height: 27px;
        //       }
        //     }
        //   }
        // }
      }
      // .top-mobile {
      //   @media (min-width: $lg) {
      //     display: none;
      //   }
      // }
      .bottom {
        width: 100%;
        margin-top: 24px;
        iframe {
          width: 100%;
          height: fit-content;
        }
      }
    }
  }
</style>

<style lang="scss">
  #benefits-carousel {
    .carousel-item {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .carousel-indicators {
      bottom: 50px;
      @media (max-width: $lg) {
        bottom: 39px;
      }
      li {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        background: #FFFFFE;
        border: none;
        opacity: 1;
        &.active {
          background: #006FDA;
          box-shadow: 0 0 4px 4px rgba(101,177,251,0.15);
        }
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      width: 19px;
      opacity: 1;
      @media (max-width: $lg) {
        width: 10px;
      }
      &-icon {
        height: 27px;
        width: 19px;
        background-image: url('~@/assets/Benefits/left-chevron.svg');
      }
    }
    .carousel-control-next {
      right: -25px;
      transform: rotate(180deg);
      @media (max-width: $lg) {
        right: 16.5px;
      }
    }
    .carousel-control-prev {
      left: -25px;
      @media (max-width: $lg) {
        left: 16.5px;
      }
    }


    .carousel-indicators {
      bottom: 25px;
      li {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #FFFFFE;
        border: none;
        opacity: 1;
        &.active {
          background: #006FDA;
          box-shadow: 0 0 4px 4px rgba(101,177,251,0.15);
        }
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      width: 10px;
      opacity: 1;
      &-icon {
        height: 27px;
        width: 19px;
        background-image: url('~@/assets/Benefits/left-chevron.svg');
      }
    }
    .carousel-control-next {
      right: 20.5px;
      transform: rotate(180deg);
    }
    .carousel-control-prev {
      left: 20.5px;
    }
  }
</style>