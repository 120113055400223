import Vue from "vue";
import Router from "vue-router"

import mainPage from '@/components/Layout/mainPage';


Vue.use(Router);

export default new Router({
    mode: 'history',
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    linkExactActiveClass: 'is-active',
    base: ``,

    routes: [
        {
            path: "/premium*",
            name: 'mainPage',
            component: mainPage
        },
        {
            path: "*",
            name: 'NotFound',
            component: mainPage
        },
    ]
});