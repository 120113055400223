<template>
  <div class="events-carousel-container">
    <div class="gallery-arrows">
      <button
        @click.prevent="slidePrev"
        id="prevArrow"
        class="navArrow left"
      >
        <svg width="19" height="27" viewBox="0 0 19 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.243.515l4.242 4.242L9 13.243l9.192 9.192-4.242 4.243-9.192-9.193-4.243-4.242L13.243.515z" fill="none" fill-rule="evenodd"/></svg>
      </button>
      <button
        @click.prevent="slideNext"
        id="nextArrow"
        class="navArrow right"
      >
        <svg width="19" height="27" viewBox="0 0 19 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.05 26.678L.808 22.435 10 13.243 1.515 4.757 5.757.515l12.728 12.728-4.242 4.242-9.193 9.193z" fill="#AAAFAF" fill-rule="evenodd"/></svg>
      </button>
    </div>
    <!-- <Hooper
      id="eventsCarousel"
      ref="eventsCarousel"
      :itemsToShow="1"
      :itemsToSlide="1"
      :mouseDrag="false"
      :touchDrag="true"
      :wheelControl="false"
      :infiniteScroll="true"
      @slide="hooperSlide"
    >
      <Slide v-for="(itemGroup, index) in organizedData" :key="prefix+index">
        <div class="event-slide">
          <EventsItemGroup :itemsGroup="itemGroup" :groupIndex="index" />
        </div>
      </Slide>
    </Hooper> -->
    <Hooper
      id="eventsCarousel"
      ref="eventsCarousel"
      :settings="hooperSettings"
      :infiniteScroll="true"
      :mouseDrag="false"
      :touchDrag="true"
      :wheelControl="false"
      @slide="hooperSlide"
    >
      <Slide v-for="(slide, index) in data" :key="'slide' + index">
        <div class="card">
        <div class="top">
          <img v-if="slide.image" :src="require('@/assets/Events/' + slide.image )">
        </div>
        <div class="bottom">
          <h4 v-html="slide.title"></h4>
          <template v-if="slide.dates">
            <p v-for="(date, i) in slide.dates" :key="'date' + index + i">
              {{ date }}
            </p>
          </template>
        </div>
      </div>
      </Slide>
      <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
    </Hooper>
  </div>
</template>


<script>
  import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
  import "hooper/dist/hooper.css";
  import EventsItemGroup from "@/components/sections/Events/EventsItemGroup";
  export default {
    name: "EventsGallery",
    components: {
      Hooper,
      Slide,
      HooperNavigation,
      EventsItemGroup
    },
    data() {
      return {
        prefix: "events-img-",
        currentImageIndex: 0,
        organizedData: [],
        windowWidth: window.innerWidth,
        hooperSettings: {
          itemsToSlide: 1,
          breakpoints: {
            992: {
              itemsToShow: 3
            },
            650: {
              itemsToShow: 2
            },
            1: {
              itemsToShow: 1
            }
          }
        }
      };
    },
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    watch: {
      data: function(newVal, oldVal) {
        this.currentImageIndex = 0;
      }
    },
    methods: {
      imageIndexSelected(newIndex) {
        let ele = this.$refs["eventsCarousel"];
        const indexMax = this.data.length - 1;
        if (newIndex < 0) {
          newIndex = indexMax;
        } else if (newIndex > indexMax) {
          newIndex = 0;
        }
        ele.slideTo(newIndex);
      },
      hooperSlide(props) {
        this.currentImageIndex = props.currentSlide;
      },
      slidePrev() {
        this.$refs["eventsCarousel"].slidePrev()
      },
      slideNext() {
        this.$refs["eventsCarousel"].slideNext()
      }
    },
    mounted() {
      let that = this
      window.addEventListener(
        "resize",
        function() {
          that.windowWidth = window.innerWidth
        },
        false
      )
      if (this.data && this.data.length > 0) {
        if (this.windowWidth > 992) {
          const groupSize = 3;
          let originalDataCopy = JSON.parse(JSON.stringify(this.data));
          this.organizedData = originalDataCopy
            .map((item, index) => {
              return index % groupSize === 0
                ? originalDataCopy.slice(index, index + groupSize)
                : null;
            })
            .filter(function(item) {
              return item;
            });
        } else if (this.windowWidth > 650 && this.windowWidth <= 992) {
          const groupSize = 2;
          let originalDataCopy = JSON.parse(JSON.stringify(this.data));
          this.organizedData = originalDataCopy
            .map((item, index) => {
              return index % groupSize === 0
                ? originalDataCopy.slice(index, index + groupSize)
                : null;
            })
            .filter(function(item) {
              return item;
            });
        } else {
          const groupSize = 1;
          let originalDataCopy = JSON.parse(JSON.stringify(this.data));
          this.organizedData = originalDataCopy
            .map((item, index) => {
              return index % groupSize === 0
                ? originalDataCopy.slice(index, index + groupSize)
                : null;
            })
            .filter(function(item) {
              return item;
            });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .events-carousel-container {
    width: 100%;
    position: relative;
    #eventsCarousel {
      height: 100%;
    }
    .event-slide {
      position: relative;
      height: 100%;
    }
    .gallery-arrows {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -45px;
      right: 0;
      width: calc(100% + 90px);
      z-index: 5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: auto;
      @media (max-width: $lg) {
        width: calc(100% + 30px);
        left: -15px;
      }
    }
    .card {
      // height: 253px;
      height: 100%;
      margin: 0 17.5px;
      .top {
        height: 182px;
        width: 100%;
        // background-image: url("~@/assets/Events/bg-image-placeholder.jpg");
        background-image: linear-gradient(180deg, #023460 0%, #023460 100%);
        // background-size: cover;
        // background-position: left top;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 182px);
        width: 100%;
        background: #1D252C;
        // box-shadow: 0 6px 30px 0 rgba(0,0,0,0.19);
        padding: 13px 14px 12px 15px;
        h4 {
          font-family: StampedeCondensed-Bold;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 18px;
          margin-bottom: 10px;
        }
        p {
          font-family: Industry-Medium;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 22px;
        }
      }
    }
  }
  .navArrow {
    transition: all 0.2s ease-in-out;
    background: transparent;
    border: none;
    svg {
      // height: 27px;
      object-fit: contain;
      path {
        fill: #AAAFAF;
        transition: fill 0.3s ease-in-out;
      }
      &:hover {
        path {
          fill: #006FDA;
        }
      }
    }
  }

  .navArrow:focus {
    outline: none;
  }
  // .display-image {
  //   width: 100%;
  //   max-width: 100%;
  //   object-fit: cover;
  //   height: 500px;
  //   @media (max-width: $sm) {
  //     height: auto;
  //   }
  // }
</style>
<style>
  /* .hooper-slide{
    height: auto!important;
  } */
</style>

