<template>
  <section id="GameDayForm1">
    <div class="header">
      Gameday Suite Details
      <div class="separator"></div>
    </div>
    <div class="content">
      <div class="row">
        <label for="companyName">Company Name</label>
        <input
          type="text"
          id="companyName"
          disabled
          v-model="formValues.companyName"
        />
      </div>
      <div class="row">
        <label for="suiteNum">Suite #</label>
        <input
          type="text"
          id="suiteNum"
          disabled
          v-model="formValues.suiteNum"
        />
      </div>
      <div class="row">
        <label :class="{ error: errors.gameDate }" for="gameDate">Game Date/Opponent</label>
        <input type="text" id="gameDate" v-model="formValues.opponent" />
        <!-- <textarea type="text" id="gameDate"></textarea> -->
      </div>
      <div class="row">
        <label :class="{ error: errors.checkIn }" for="checkIn">Who should we check in with?</label>
        <input type="text" id="checkIn" v-model="formValues.whoCheckIn" />
        <!-- <textarea type="text" id="checkIn"></textarea> -->
      </div>
      <div class="row">
        <label :class="{ error: errors.specialGuests }" for="specialGuests"
          >Any special guests in the suite we should know about
        </label>
        <input
          type="text"
          id="specialGuests"
          v-model="formValues.specialGuests"
        />
        <!-- <textarea type="text" id="specialGuests"></textarea> -->
      </div>
      <div class="row">
        <label for="celebrating" :class="{ error: errors.celebrating }">
          Are you celebrating anything this week (birthday, anniversary,
          etc.)?
        </label>
        <input type="text" id="celebrating" v-model="formValues.celebrating" />
        <!-- <textarea type="text" id="celebrating"></textarea> -->
      </div>
      <div class="row last">
        <label for="additionalInfo" :class="{ error: errors.additionalInfo }">
          Any additional information we should know?
        </label>
        <textarea
          type="text"
          id="additionalInfo"
          v-model="formValues.additionalInfo"
        ></textarea>
      </div>
      <button v-if="!updateButton" class="submit-btn" @click="() => submitForm()">Submit</button>
      <button v-else class="submit-btn" @click="() => submitForm()">Update</button>
    </div>
  </section>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import axios from "axios";

  export default {
    name: "GameDayForm1",
    computed: {
      ...mapState(["global"]),
    },
    data() {
      return {
        errors: {},
        formValues: {
          companyName: "",
          suiteNum: "",
          opponent: "",
          whoCheckIn: "",
          specialGuests: "",
          celebrating: "",
          additionalInfo: "",
        },
        updateButton: false,
        optionals: ["celebrating"]
      };
    },
    mounted() {
      this.formValues.companyName = this.global.userInfo.companyName;
      this.formValues.suiteNum = this.global.userInfo.suiteNum;

      this.copyOldFormVals();
    },
    methods: {
      copyOldFormVals() {
        const oldFormInfo = this.global.formInfo.find(
          (form) => form.formId === "2::gameday_suite_details"
        );
        if (oldFormInfo === undefined) return;
        const oldFormVals = JSON.parse(oldFormInfo.formValues);
        if (!oldFormVals) return;
        this.formValues = { ...this.formValues, ...oldFormVals };
        this.updateButton = true;
      },
      checkEmpties() {
        let emptiesExist = false;
        Object.keys(this.formValues).forEach((key) => {
          if (this.formValues[key].trim() === "" && !this.optionals.includes(key)) {
            emptiesExist = true;
            this.errors = {
              ...this.errors,
              [key]: true,
            };
          }
        });

        return emptiesExist;
      },
      async submitForm() {
        const purl = this.global.userInfo.purl;
        if (!purl) return;
        this.errors = {};
        if (this.checkEmpties()) return;
        this.formSubmitLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_API_LOCATION + "/api/router/Forms.php",
          {
            action: "submitSurvey",
            purl: purl,
            formId: "2::gameday_suite_details",
            formValues: JSON.stringify(this.formValues),
            accountId: this.global.userInfo.accountId,
            timestamp: new Date(),
          }
        );
        this.formSubmitLoading = false;
        if (response.data.status === "success") {
          this.$swal(
            "Success!",
            "You have successfully submitted the form!",
            "success"
          );
          this.updateButton = true;
          this.setFormInfo({
            formId: "2::gameday_suite_details",
            formValues: this.formValues,
          });
        } else {
          this.$swal("Error!", "Please try again later", "error");
        }
      },
      ...mapActions(["setFormInfo"])
    }
  };
</script>

<style lang="scss" scoped>
  #GameDayForm1 {
    width: 100%;
    max-width: 491px;
    height: 100%;
    .header {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 46px;
      padding: 0 0 4px 28px;
      background-color: rgba(17, 22, 26, 0.7);
      font-family: Industry-Bold;
      font-size: 14px;
      color: #fffffe;
      letter-spacing: 0;
      text-align: center;
      line-height: 19px;
      @media (max-width: $lg) {
        font-size: 12px;
      }
      .separator {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-image: linear-gradient(
          270deg,
          #d8d8d8 0%,
          #f7f7f7 33%,
          #d8d8d8 100%
        );
        box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.2);
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      height: calc(100% - 46px);
      width: 100%;
      background-color: rgba(29, 37, 44, 0.7);
      padding: 32px 23px 34px 28px;
      .row {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px 0;
        &.last {
          margin-bottom: 31px;
        }
        label {
          font-family: Industry-Demi;
          font-size: 14px;
          color: #c1c1c1;
          letter-spacing: 0;
          line-height: 27px;
          margin-bottom: 3px;
          &.error {
            color: red;
          }
          @media (max-width: $lg) {
            font-size: 12px;
            line-height: 25px;
          }
        }
        input {
          font-family: Industry-Demi;
          font-size: 16px;
          color: #fffffe;
          letter-spacing: 0;
          line-height: 27px;
          background: transparent;
          border: none;
          border-bottom: 2px solid #ffffff;
          outline: none;
          padding: 0 0 10px 0;
          @media (max-width: $lg) {
            font-size: 13px;
            line-height: 25px;
          }
          &:focus {
            border-bottom: 2px solid #006fda;
          }
        }
        textarea {
          font-family: Industry-Demi;
          font-size: 16px;
          color: #fffffe;
          letter-spacing: 0;
          line-height: 27px;
          height: auto;
          background: transparent;
          border: none;
          border-bottom: 2px solid #ffffff;
          outline: none;
          padding: 0 0 10px 0;
          @media (max-width: $lg) {
            font-size: 13px;
            line-height: 25px;
          }
          &:focus {
            border-bottom: 2px solid #006fda;
          }
        }
      }
      .submit-btn {
        height: 40px;
        width: 146px;
        background: transparent;
        border: 2px solid #006fda;
        box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.1);
        font-family: StampedeCondensed-Bold;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 16px;
        outline: none;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: #006fda;
          box-shadow: 0 0 4px 4px rgba(101, 177, 251, 0.2);
          color: #ffffff;
        }
        @media (max-width: $lg) {
          font-size: 14px;
          line-height: 14px;
          width: 140px;
          height: 38px;
        }
      }
    }
  }
</style>