<template>
  <section id="GetToKnowYou" class="getToKnowYou">
    <div id="GetToKnowYouMarker"></div>
    <div class="brick"></div>
    <div
      class="container"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="200"
    >
      <div class="title">
        <h1>Let the Colts</h1>
        <img src="@/assets/GetToKnowYou/gettoknowyou.png" />
      </div>
      <button v-b-modal.survey>Fill in survey</button>
    </div>
    <b-modal id="survey" hide-header hide-footer>
      <div class="top">
        <p>Let the Colts get to know you better!</p>

        <button @click="$bvModal.hide('survey')">
          <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.32 3.244L11.661 8.9l5.657 5.657-2.828 2.829-5.657-5.657-5.657 5.657L.35 14.557 6.006 8.9.349 3.244 3.177.415l5.657 5.657L14.491.415l2.828 2.829z" fill="none" fill-rule="evenodd"/></svg>
        </button>
        <div class="spacer"></div>
      </div>
      <div class="content">
        <div id="surveyForm">
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.name }" for="name">Name:</label>
              <input name="name" autofocus v-model="survey.name" />
            </div>
            <div class="item">
              <label for="birthdate">Birthdate:</label>
              <input type="date" name="birthdate" v-model="survey.birthdate" />
            </div>
          </div>
          <div class="row row2">
            <div class="item">
              <label :class="{ error: errors.bio }" for="bio">Brief Bio:</label>
              <textarea name="bio" v-model="survey.bio"></textarea>
            </div>
          </div>
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.hometown }" for="hometown">Hometown:</label>
              <input name="hometown" v-model="survey.hometown" />
            </div>
            <div class="item">
              <label :class="{ error: errors.almaMater }" for="almaMater">Alma Mater:</label>
              <input name="almaMater" v-model="survey.almaMater" />
            </div>
          </div>
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.militaryService }" for="militaryService">
                Military Service (if applicable):
              </label>
              <textarea
                name="militaryService"
                v-model="survey.militaryService"
              ></textarea>
            </div>
            <div class="item">
              <label :class="{ error: errors.hobbies }" for="hobbies">Current Hobbies:</label>
              <textarea name="hobbies" v-model="survey.hobbies"></textarea>
            </div>
          </div>
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.involvement }" for="involvement">
                Community and/or Non-Profit Involvement:<br />
                <p>
                  (i.e. Are you a board member for any organizations in the
                  community?)
                </p>
              </label>
              <textarea
                name="involvement"
                v-model="survey.involvement"
              ></textarea>
            </div>
            <div class="item">
              <label :class="{ error: errors.favoriteEats }" for="favoriteEats">Favorite Place to Eat:</label>
              <textarea
                name="favoriteEats"
                v-model="survey.favoriteEats"
              ></textarea>
            </div>
          </div>
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.favoritePlayer }" for="favoritePlayer"
                >Favorite Colts player (past of present):</label
              >
              <input name="favoritePlayer" v-model="survey.favoritePlayer" />
            </div>
            <div class="item">
              <label :class="{ error: errors.shirtSize }" for="shirtSize">Shirt Size:</label>
              <select name="shirtSize" v-model="survey.shirtSize">
                <option value="" selected disabled hidden>Select Option</option>
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
                <option value="xxl">XXL</option>
              </select>
            </div>
          </div>
          <div class="row row1">
            <div class="item">
              <label :class="{ error: errors.spouse }" for="spouse">Spouse Name:</label>
              <input name="spouse" v-model="survey.spouse" />
            </div>
            <div class="item">
              <label :class="{ error: errors.children }" for="children">Do you have children?</label>
              <select name="children" v-model="survey.children">
                <option value="" selected hidden>Select Option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <div
            class="row row1"
            v-for="(child, index) in survey.childrenInfo"
            :key="'child' + index"
          >
            <div class="item">
              <label for="childName1">Child's Name:</label>
              <input
                name="childName1"
                v-model="survey.childrenInfo[index].name"
              />
            </div>
            <div class="item">
              <label for="childAge1">Age:</label>
              <select name="childAge1" v-model="survey.childrenInfo[index].age">
                <option value="" selected hidden>Select Option</option>
                <option value="0-2">0 - 2</option>
                <option value="3-5">3 - 5</option>
                <option value="6-8">6 - 8</option>
                <option value="9-12">9 -12</option>
                <option value="13-15">13 - 15</option>
                <option value="16-18">16 - 18</option>
                <option value="19+">19+</option>
              </select>
            </div>
          </div>

          <div
            class="row row1"
            v-for="(child, index) in survey.grandchildrenInfo"
            :key="'grandchild' + index"
          >
            <div class="item">
              <label for="grandchildName1">Grandchild's Name:</label>
              <input
                name="grandchildName1"
                v-model="survey.grandchildrenInfo[index].name"
              />
            </div>
            <div class="item">
              <label for="grandchildAge1">Age:</label>
              <select
                name="grandchildAge1"
                v-model="survey.grandchildrenInfo[index].age"
              >
                <option value="" selected hidden>Select Option</option>
                <option value="0-2">0 - 2</option>
                <option value="3-5">3 - 5</option>
                <option value="6-8">6 - 8</option>
                <option value="9-12">9 -12</option>
                <option value="13-15">13 - 15</option>
                <option value="16-18">16 - 18</option>
                <option value="19+">19+</option>
              </select>
            </div>
          </div>
          <div class="row row3" v-if="survey.children !== 'yes'">
            <div v-if="!updateButton" class="submit" @click="submitForm()">Submit</div>
            <div v-else class="submit" @click="submitForm()">Update</div>
          </div>
          <div class="row row4" v-else>
            <div class="left">
              <div
                @click="addChild"
                :class="{ disabled: survey.childrenInfo.length >= 12 }"
                class="child"
              >
                Add a Child
              </div>
              <div
                @click="addGrandchild"
                :class="{ disabled: survey.grandchildrenInfo.length >= 12 }"
                class="child"
              >
                Add a Grandchild
              </div>
            </div>
            <div v-if="!updateButton" class="submit" @click="submitForm()">Submit</div>
            <div v-else class="submit" @click="submitForm()">Update</div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import axios from "axios";

  export default {
    name: "GetToKnowYou",
    computed: {
      ...mapState(["global"]),
    },
    data() {
      return {
        survey: {
          name: "",
          birthdate: "",
          bio: "",
          hometown: "",
          almaMater: "",
          militaryService: "",
          hobbies: "",
          involvement: "",
          favoriteEats: "",
          favoritePlayer: "",
          shirtSize: "",
          spouse: "",
          children: "",
          childrenInfo: [],
          grandchildrenInfo: [],
        },
        optionals: ["birthdate" ,"militaryService", "childrenInfo", "grandchildrenInfo", "spouse", "involvement"],
        updateButton: false,
        errors: {}
      };
    },
    mounted() {
      this.copyOldFormVals();
    },
    methods: {
      addChild() {
        this.survey.childrenInfo.push({
          name: "",
          age: "",
        });
      },
      addGrandchild() {
        this.survey.grandchildrenInfo.push({
          name: "",
          age: "",
        });
      },
      copyOldFormVals() {
        const oldFormInfo = this.global.formInfo.find(
          (form) => form.formId === "1::get_to_know_you"
        );
        if (oldFormInfo === undefined) return;
        const oldFormVals = JSON.parse(oldFormInfo.formValues);
        if (!oldFormVals) return;
        this.survey = { ...this.survey, ...oldFormVals };
        this.updateButton = true;
      },
      checkEmpties() {
        let emptiesExist = false;
        Object.keys(this.survey).forEach((key) => {
          if (!this.optionals.includes(key) && this.survey[key].trim() === "") {
            emptiesExist = true;
            this.errors = {
              ...this.errors,
              [key]: true,
            };
          }
        });
        return emptiesExist;
      },
      async submitForm() {
        const purl = this.global.userInfo.purl;
        if (!purl) return;
        this.errors = {};
        if (this.checkEmpties()) return;
        this.formSubmitLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_API_LOCATION + "/api/router/Forms.php",
          {
            action: "submitSurvey",
            purl: purl,
            formId: "1::get_to_know_you",
            formValues: JSON.stringify(this.survey),
            accountId: this.global.userInfo.accountId,
            timestamp: new Date(),
          }
        );
        this.formSubmitLoading = false;
        if (response.data.status === "success") {
          this.$swal(
            "Success!",
            "You have successfully submitted the form!",
            "success"
          );
          this.updateButton = true;
          this.setFormInfo({
            formId: "1::get_to_know_you",
            formValues: this.survey,
          });
        } else {
          this.$swal("Error!", "Please try again later", "error");
        }
      },
      ...mapActions(["setFormInfo"])
    },
  };
</script>

<style lang="scss" scoped>
#GetToKnowYou {
  position: relative;
  height: 318px;
  width: 100%;
  background-image: url("~@/assets/GetToKnowYou/HOME-BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  @media (min-width: $lg) {
    background-attachment: fixed;
  }
  @media (max-width: $lg) {
    // background-image: url('~@/assets/Landing/landing-mobile-bg.jpg');
    background-image: url("~@/assets/Landing/hero-mobile-bg.jpg");
  }
  #GetToKnowYouMarker {
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    @media (max-width: $lg) {
      top: -60px;
    }
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 45px 0 0 0;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      h1 {
        font-family: StampedeCondensed-Bold;
        font-size: 80px;
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke: 2px #ffffff;
        letter-spacing: 0;
        line-height: 68px;
        @media (max-width: $lg) {
          font-size: 50px;
          line-height: 50px;
        }
        @media (max-width: 374px) {
          font-size: 40px;
          line-height: 44px;
        }
      }
      img {
        margin-top: -20px;
        @media (max-width: $lg) {
          width: 380px;
          max-width: 80%;
          margin-top: -12px;
        }
      }
    }
    button {
      font-family: StampedeCondensed-Bold;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      line-height: 16px;
      height: 40px;
      width: 146px;
      background: transparent;
      border: 2px solid #006fda;
      box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.1);
      margin-top: 25.8px;
      outline: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #006fda;
        box-shadow: 0 0 4px 4px rgba(101, 177, 251, 0.2);
        color: #ffffff;
      }
    }
  }
}
</style>

<style lang="scss">
#survey {
  background-color: rgba(29, 37, 44, 0.95);
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    margin: 0;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0;
    opacity: 0.88;
    background: transparent;
    width: 660px;
    height: calc(100vh - 118px);
    opacity: 1;
    border-radius: 0;
    border: none;
    .modal-body {
      padding: 0 0 50px 0;
      height: fit-content;
      width: 100%;
      border: none;
      .top {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 0 0 4px 0;
        p {
          font-family: Industry-Demi;
          font-size: 14px;
          color: #fffffe;
          letter-spacing: 0;
          line-height: 27px;
        }
        button {
          background: transparent;
          border: none;
          svg {
            height: 16px;
            path {
              fill: #ffffff;
              transition: fill 0.3s ease-in-out;
            }
            &:hover {
              path {
                fill: #006fda;
              }
            }
          }
        }
        .spacer {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          width: 100%;
          background-image: linear-gradient(
            270deg,
            #d8d8d8 0%,
            #f7f7f7 33%,
            #d8d8d8 100%
          );
          box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.2);
        }
      }
      .content {
        min-height: calc(100% - 46px);
        // max-height: calc(100% - 46px);
        padding: 45px 0 0 0;
        // overflow: auto;
        #surveyForm {
          .row {
            display: flex;
            flex-direction: row;
            margin: 0;
            width: 100%;
            margin-bottom: 20px;
            &.row1 {
              .item {
                width: calc(50% - 11px);
                &:first-child {
                  margin-right: 22px;
                }
              }
            }
            &.row2 {
              .item {
                width: 100%;
              }
            }
            &.row3 {
              justify-content: flex-end;
              padding-top: 20px;
            }
            &.row4 {
              justify-content: space-between;
              padding-top: 20px;
              .left {
                display: flex;
                flex-direction: row;
                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-family: StampedeCondensed-Bold;
                  font-size: 16px;
                  color: #ffffff;
                  letter-spacing: 0;
                  text-align: center;
                  line-height: 16px;
                  background: rgba(255, 255, 255, 0);
                  box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.1);
                  height: 40px;
                  width: 160px;
                  outline: none;
                  border: 2px solid #d8d8d8;
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  &:hover {
                    color: #aaafaf;
                    background: #ffffff;
                    box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.1);
                    border: 2px solid #ffffff;
                  }
                  &:first-child {
                    margin-right: 20px;
                  }
                  &.disabled {
                    border: 2px solid grey;
                    color: grey;
                    cursor: default;
                  }
                }
              }
            }
            .submit {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: StampedeCondensed-Bold;
              font-size: 16px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: center;
              line-height: 16px;
              background: transparent;
              outline: none;
              border: 2px solid #006fda;
              box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.1);
              width: 146px;
              height: 40px;
              outline: none;
              transition: all 0.3s ease-in-out;
              cursor: pointer;
              &:hover {
                background: #006fda;
                box-shadow: 0 0 4px 4px rgba(101, 177, 251, 0.2);
                color: #ffffff;
              }
            }
            .item {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              label {
                font-family: Industry-Demi;
                font-size: 14px;
                color: #c1c1c1;
                letter-spacing: 0;
                line-height: 27px;
                margin: 0;
                
                p {
                  font-family: Industry-Demi;
                  font-size: 12px;
                  color: #c1c1c1;
                  letter-spacing: 0;
                  line-height: 18px;
                }
                &.error {
                  color: red;
                  p {
                    color: red;
                  }
                }
              }
              input {
                font-family: Industry-Demi;
                font-size: 16px;
                color: #fffffe;
                letter-spacing: 0;
                line-height: 27px;
                background: transparent;
                border: none;
                border-bottom: 2px solid #ffffff;
                outline: none;
                padding-bottom: 12px;
                &:focus {
                  border-bottom: 2px solid #006fda;
                }
              }
              textarea {
                font-family: Industry-Demi;
                font-size: 16px;
                color: #fffffe;
                letter-spacing: 0;
                line-height: 27px;
                background: transparent;
                border: none;
                border-bottom: 2px solid #ffffff;
                outline: none;
                padding-bottom: 12px;
                &:focus {
                  border-bottom: 2px solid #006fda;
                }
              }
              input[type="date"]::-webkit-calendar-picker-indicator {
                filter: invert(100%);
              }
              select {
                font-family: Industry-Demi;
                font-size: 14px;
                color: rgba(116, 116, 116, 0.8);
                letter-spacing: 0;
                line-height: 27px;
                height: 34px;
                background: transparent;
                border: 2px solid #ffffff;
                margin-top: 8px;
                padding: 0 12px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image: url("~@/assets/GetToKnowYou/down-arrow.svg");
                background-repeat: no-repeat;
                background-position-x: 97%;
                background-position-y: 50%;
                background-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>