import Vue from 'vue';
import App from './App.vue';
import VueScrollTo from 'vue-scrollto';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueScrollactive from 'vue-scrollactive';
// import { detect } from 'detect-browser';
import router from './router/index.js';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { detect } from "detect-browser";

library.add(faSpinner);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(BootstrapVue);

Vue.use(VueSweetalert2);

Vue.use(VueScrollactive);

// Browser Detection
axios
  .post(process.env.VUE_APP_API_LOCATION + "/api/router/Tracking.php", {
    site: 'common',
    action: "tracking",
    label: `browser:${detect().name}`,
    category: "browser",
  })
  .catch(() => {
    window.console.log("failed tracking");
  });

Vue.directive("ePitchTracking", {
  bind(el, binding) {
    if (!binding.value) return;
    const label = binding.value.label;
    const category = binding.value.category;
    if (!label || !category) return;
    el.addEventListener("click", () => {
      axios
        .post(process.env.VUE_APP_API_LOCATION + "/api/router/Tracking.php", {
          action: "tracking",
          label,
          category,
        })
        .catch(() => {
          window.console.log("failed tracking");
        });
    });
  },
});

new Vue({
  store,
  created() {
    AOS.init();
  },
  router,
  render: h => h(App)
}).$mount('#app');
