<template>
  <section id="MobileAccordion">
    <b-card
      no-body
      v-for="(item, index) in accordionData"
      :key="'item' + index"
      v-b-toggle="'accordion-' + uniquePrefix + index"
    >
      <b-card-header>
        <h1>{{ item.title }}</h1>
        <b-button>
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.071 14.95L7.243 12.12 3 7.88 5.828 5.05l4.243 4.243 4.243-4.243 2.828 2.829-7.07 7.07z" fill="#FFF" fill-rule="evenodd"/></svg>
        </b-button>
      </b-card-header>
      <b-collapse :id="'accordion-' + uniquePrefix + index" accordion="my-accordion">
        <div class="accordion-top">
          <div class="spacer"></div>
          <h2 v-html="item.cardTitle"></h2>
          <p>{{ item.copy }}</p>
        </div>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
  export default {
    name: "MobileAccordion",
    props: {
      accordionData: {
        type: Array,
        required: true
      },
      uniquePrefix: {
        type: String,
        required: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  #MobileAccordion {
    .card {
      margin-top: 10px;
      border: none;
      border-radius: 0;
      outline: none;
      background: transparent;
      &.collapsed {
        .card-header {
          background: #000000;
        }
        .not-expand {
          display: none;
        }
      }
      &.not-collapsed {
        .card-header {
          background: #023460;
        }
        svg {
          transform: rotate(180deg);
        }
        .expand {
          display: none;
        }
      }
      .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: none;
        border-radius: 0;
        padding: 15px 18px 13px 28px;
        h1 {
          font-family: Stampede-Regular;
          color: #FFFFFF;
          letter-spacing: 0;
          font-size: 14px;
          line-height: 18px;
          }
        }
        .btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          svg {
            outline: none;
            margin-left: 12.7px;
            height: 26px;
            width: 27px;
            transform: rotate(-90deg);
          }
        }
      }
      .collapse {
        display: flex;
        flex-direction: column;
        width: 100%;
        .accordion-top {
          position: relative;
          background: #023460;
          box-shadow: 0 6px 30px 0 rgba(0,0,0,0.50);
          padding: 36px 28px 32px 31px;
          .spacer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
            box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
          }
          h2 {
            font-family: StampedeCondensed-Bold;
            font-size: 46px;
            color: #FFFFFF;
            letter-spacing: 0.5px;
            line-height: 40px;
          }
          p {
            font-family: Industry-Demi;
            font-size: 14px;
            color: #FFFFFE;
            letter-spacing: 0;
            line-height: 27px;
            border: none;
            padding-top: 11px;
          }
        }
        // .accordion-bottom {
        //   position: relative;
        //   background: transparent;
        //   height: fit-content;

        //   @media (max-width: $lg) {
        //     height: fit-content;
        //   }
        //   &__title {
        //     height: 98px;
        //     width: 100%;
        //     background: rgba(29, 37, 44, 0.7);
        //     padding-top: 5px;
        //       font-family: Industry-Bold;
        //     p {
        //       font-size: 14px;
        //       color: #FFFFFF;
        //       letter-spacing: 0;
        //       text-align: center;
        //       line-height: 20px;
        //       background: rgba(29, 37, 44, 0.7);
        //       @media (max-width: $lg) {
        //         font-size: 12px;
        //       }
        //     }
        //   }
        //   ul {
        //     position: relative;
        //     list-style: none;
        //     display: flex;
        //     flex-direction: row;
        //     background: #F9F9F9;
        //     box-shadow: 0 2px 14px 0 rgba(0,0,0,0.20);
        //     margin: -65px 10px 0 10px;
        //     padding: 0;
        //     @media (max-width: $lg) {
        //       flex-direction: column;
        //       height: fit-content;
        //     }
        //     li {
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: flex-start;
        //       align-items: center;
        //       width: calc(100% / 3);
        //       background-image: linear-gradient(180deg,
        //         rgba(163,169,172,0.00) 0%,
        //         rgba(163,169,172,0.13) 99%
        //       );
        //       padding: 20px 10px 17px 10px;
        //       margin: 10px;
        //       @media (max-width: $lg) {
        //         width: calc(100% - 20px);
        //         padding: 18px 10px 15px 10px;
        //       }
        //       &:nth-child(2) {
        //         margin: 10px 0;
        //         @media (max-width: $lg) {
        //           margin: 0 10px;
        //         }
        //       }
        //       h4 {
        //         font-family: Stampede-Regular;
        //         font-size: 24px;
        //         color: #023460;
        //         letter-spacing: 0;
        //         text-align: center;
        //         line-height: 24px;
        //         margin-bottom: 8px;
        //         @media (max-width: $lg) {
        //           font-size: 20px;
        //           line-height: 24px;
        //         }
        //       }
        //       span {
        //         width: 80%;
        //         font-family: Industry-Medium;
        //         font-size: 14px;
        //         color: #1D252C;
        //         letter-spacing: 0;
        //         text-align: center;
        //         line-height: 21px;
        //       }
        //     }
        //   }
        // }
      }
    }
</style>