export const preloadData = () => {
    const images = [
        // require("@/assets/images/backgrounds/SOMEIMAGE.jpg"),
    ];
    const fonts = [
        // new FontFace(
        //     "FuturaStd-Book",
        //     `url(${require("@/assets/fonts/FuturaStd-Book.woff2")}) format('woff2'), url(${require("@/assets/fonts/FuturaStd-Book.woff")}) format('woff')`
        // )
    ];
    return {
        images,
        fonts
    };
};
