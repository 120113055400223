<template>
  <div class="events-item-group">
    <div
      class="card"
      v-for="(item, index) in itemsGroup"
      :key="'eventsItem' + groupIndex + index"
    >
      <div class="top">
        <img :src="require('@/assets/Events/' + item.image )">
      </div>
      <div class="bottom">
        <h4 v-html="item.title"></h4>
        <p v-for="(date, i) in item.dates" :key="'date' + index + i">
          {{ date }}
        </p>
      </div>
    </div>
  </div>
</template><script>
export default {
  name: "EventsItemGroup",
  props: {
    itemsGroup: {
      type: Array,
      required: true
    },
    groupIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    itemsLength() {
      return this.itemsGroup.length
    }
  },
  watch: {
    // itemsLength (val, oldVal) {
    //   this.groupIndex +=1
    // }
    itemsGroup: function (newVal, oldVal) {
      console.log(newVal, oldVal)
    }
  }
};
</script><style lang="scss" scoped>
.events-item-group {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: none;
  @media (max-width: $lg) {
    margin: 0 auto;
    width: calc(100% - 60px);
  }
  @media (max-width: 650px) {
    justify-content: center;
  }
  .card {
    // height: 253px;
    height: 100%;
    width: calc((100% / 3) - 35px);
    @media (max-width: $xl) {
      width: calc((100% / 3) - 20px);
    }
    @media (max-width: $lg) {
      width: calc(50% - 35px);
    }
    @media (max-width: 650px) {
      width: 100%;
    }
    .top {
      height: 182px;
      width: 100%;
      // background-image: url("~@/assets/Events/bg-image-placeholder.jpg");
      background-image: linear-gradient(180deg, #023460 0%, #023460 100%);
      // background-size: cover;
      // background-position: left top;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 120px;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      align-items: center;
      // height: 71px;
      height: calc(100% - 182px);
      width: 100%;
      background: #1D252C;
      box-shadow: 0 6px 30px 0 rgba(0,0,0,0.19);
      // padding-bottom: 8px;
      padding: 13px 14px 12px 15px;
      h4 {
        font-family: StampedeCondensed-Bold;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px;
        margin-bottom: 10px;
      }
      p {
        font-family: Industry-Medium;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 22px;
      }
    }
  }
}
</style>