<template>
  <section id="GameDayForm2">
    <div class="header">
      <div
        class="header__item"
        :class="{ active: additionalTickets }"
        @click="additionalTickets = true, suiteUpgrade = false"
      >
        Additional Ticket Requests
        <div class="rectangle"></div>
      </div>
      <div
        class="header__item"
        :class="{ active: suiteUpgrade }"
        @click="additionalTickets = false, suiteUpgrade = true"
      >
        Suite Upgrade Request
        <div class="rectangle"></div>
      </div>
    </div>
    <div class="content" v-if="additionalTickets">
      <div class="row row1">
        <p>How many additional tickets are you requesting?</p>
        <b-form-select v-model="additionalTicketRequests.ticketsRequested">
          <b-form-select-option value="1">1</b-form-select-option>
          <b-form-select-option value="2">2</b-form-select-option>
          <b-form-select-option value="3">3</b-form-select-option>
          <b-form-select-option value="4">4</b-form-select-option>
          <b-form-select-option value="5">5</b-form-select-option>
          <b-form-select-option value="6">6</b-form-select-option>
          <b-form-select-option value="7">7</b-form-select-option>
          <b-form-select-option value="8">8</b-form-select-option>
          <b-form-select-option value="9">9</b-form-select-option>
          <b-form-select-option value="10">10</b-form-select-option>
        </b-form-select>
      </div>
      <p class="form__title">Which game(s) are you requesting additional tickets for:</p>
      <div class="form__checkmark--container">
        <div
          class="form__checkmark"
          v-for="(team, index) in checkboxOptions"
          :key="'team' + index"
          @click="checked(team)"
        >
          <div
            class="form__checkmark--box"
            :class="{ active: additionalTicketRequests.checkboxes.includes(team) }"
          ></div>
          <div class="form__checkmark--title">{{ team.name }}</div>
        </div>
      </div>
      <div class="row row2">
        <p>Type of Ticket:</p>
        <b-form-select v-model="additionalTicketRequests.typeOfTicket">
          <b-form-select-option value="Suite SROs (Standing Room Only)">Suite SROs (Standing Room Only)</b-form-select-option>
          <b-form-select-option value="Club Seating">Club Seating</b-form-select-option>
          <b-form-select-option value="Best Available General Seating">Best Available General Seating</b-form-select-option>
          <b-form-select-option value="Upper Level Seating">Upper Level Seating</b-form-select-option>
        </b-form-select>
      </div>
      <div class="submit-btn" @click="submitForm1()">Submit</div>
    </div>
    <div class="content" v-if="suiteUpgrade">
      <form id="suiteUpgrade">
        <div class="group">
          <label for="companyName">Company Name:</label>
          <input name="companyName" :value="upgradeRequest.companyName" disabled>
        </div>
        <div class="group">
          <label for="suiteNumber">Current Suite Number:</label>
          <input name="suiteNumber" :value="upgradeRequest.suiteNumber" disabled>
        </div>
        <div class="group">
          <label :class="{ error: errors.stayCurrentSuite }" for="stay">
            Would you like to stay in your current suite type, size?
          </label>
          <input name="stay" v-model="upgradeRequest.stayCurrentSuite">
          <!-- <textarea name="stay"></textarea> -->
        </div>
        <div class="group">
          <label :class="{ error: errors.relocateLocation }" for="relocate">
            What part of the stadium would you be interested in re-locating to?
          </label>
          <input name="suiteNumber" v-model="upgradeRequest.relocateLocation">
          <!-- <textarea name="suiteNumber"></textarea> -->
        </div>
        <div v-if="!updateButton" class="submit-btn" @click="submitForm2()">Submit</div>
        <div v-else class="submit-btn" @click="submitForm2()">Update</div>
      </form>
    </div>
  </section>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import axios from "axios";

  export default {
    name: "GameDayForm2",
    computed: {
      ...mapState(["global"])
    },
    data() {
      return {
        additionalTickets: true,
        suiteUpgrade: false,
        errors2: {},
        additionalTicketRequests: {
          ticketsRequested: 1,
          typeOfTicket: "Suite SROs (Standing Room Only)",
          checkboxes: []
        },
        checkboxOptions: [
          { name: "Tampa Bay Buccaneers" },
          { name: "Houston Texans" },
          { name: "Jacksonville Jaguars" },
          { name: "Tennessee Titans" },
          { name: "New York Jets" },
          { name: "New England Patriots" },
          { name: "Seattle Seahawks" },
          { name: "LA Rams" },
          { name: "Las Vegas Raiders" }
        ],
        upgradeRequest: {
          companyName: "",
          suiteNumber: "",
          stayCurrentSuite: "",
          relocateLocation: ""
        },
        errors: {},
        updateButton: false
      }
    },
    mounted() {
      this.upgradeRequest.companyName = this.global.userInfo.companyName;
      this.upgradeRequest.suiteNumber = this.global.userInfo.suiteNum;

      this.copyOldFormVals2();
    },
    methods: {
      checked(team) {
        let alreadyExists = this.additionalTicketRequests.checkboxes.includes(team)

        if (alreadyExists) {
          this.additionalTicketRequests.checkboxes = this.additionalTicketRequests.checkboxes.filter(
            // (selectedTeam) => {
            //   return selectedTeam != team
            // }
            (selectedTeam) => selectedTeam != team
          )
        } else {
          this.additionalTicketRequests.checkboxes.push(team)
        }
      },
      copyOldFormVals2() {
        const oldFormInfo = this.global.formInfo.find(
          (form) => form.formId === "4::suite_upgrade_request"
        );
        if (oldFormInfo === undefined) return;
        const oldFormVals = JSON.parse(oldFormInfo.formValues);
        if (!oldFormVals) return;
        this.upgradeRequest = { ...this.upgradeRequest, ...oldFormVals };
        this.updateButton = true;
      },
      checkEmpties2() {
        let emptiesExist = false;
        Object.keys(this.upgradeRequest).forEach((key) => {
          if (this.upgradeRequest[key].trim() === "") {
            emptiesExist = true;
            this.errors = {
              ...this.errors,
              [key]: true,
            };
          }
        });
        return emptiesExist;
      },
      // checkEmpties1() {
      //   let emptiesExist = false;
      //   Object.keys(this.additionalTicketRequests).forEach((key) => {
      //     if (this.additionalTicketRequests[key].trim() === "") {
      //       emptiesExist = true;
      //       this.errors = {
      //         ...this.errors2,
      //         [key]: true,
      //       };
      //     }
      //   });

      //   return emptiesExist;
      // },
      async submitForm2() {
        const purl = this.global.userInfo.purl;
        if (!purl) return;
        this.errors2 = {};
        if (this.checkEmpties2()) return;
        this.formSubmitLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_API_LOCATION + "/api/router/Forms.php",
          {
            action: "submitSurvey",
            purl: purl,
            formId: "4::suite_upgrade_request",
            formValues: JSON.stringify(this.upgradeRequest),
            accountId: this.global.userInfo.accountId,
            timestamp: new Date(),
          }
        );
        this.formSubmitLoading = false;
        if (response.data.status === "success") {
          this.$swal(
            "Success!",
            "You have successfully submitted the form!",
            "success"
          );
          this.updateButton = true;
          this.setFormInfo({
            formId: "4::suite_upgrade_request",
            formValues: this.upgradeRequest,
          });
        } else {
          this.$swal("Error!", "Please try again later", "error");
        }
      },
      async submitForm1() {
        const purl = this.global.userInfo.purl;
        if (!purl) return;
        this.errors2 = {};
        // if (this.checkEmpties1()) return;
        this.formSubmitLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_API_LOCATION + "/api/router/Forms.php",
          {
            action: "submitSurvey",
            purl: purl,
            formId: "3::additional_ticket_requests",
            formValues: JSON.stringify(this.additionalTicketRequests),
            accountId: this.global.userInfo.accountId,
            timestamp: new Date(),
          }
        );
        this.formSubmitLoading = false;
        if (response.data.status === "success") {
          this.$swal(
            "Success!",
            "You have successfully submitted the form!",
            "success"
          );
          this.additionalTicketRequests = {
            ticketsRequested: 1,
            typeOfTicket: "Suite SROs (Standing Room Only)",
            checkboxes: []
          }
          this.setFormInfo({
            formId: "3::additional_ticket_requests",
            formValues: this.additionalTicketRequests,
          });
        } else {
          this.$swal("Error!", "Please try again later", "error");
        }
      },
      ...mapActions(["setFormInfo"])
    }
  }
</script>

<style lang="scss" scoped>
  #GameDayForm2 {
    display: flex;
    flex-direction: column;
    width: 500px;
    background: rgba(29,37,44,0.7);
    @media (max-width: $lg) {
      width: 100%;
      max-width: 500px;
    }
    .header {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 46px;
      &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        font-family: Industry-Demi;
        font-size: 14px;
        color: #FFFFFE;
        letter-spacing: 0;
        line-height: 27px;
        cursor: pointer;
        @media (max-width: $lg) {
          font-size: 12px;
          line-height: 13px;
          padding-left: 15px;
        }
        &.active {
          background: rgba(17,22,26,0.7);
          .rectangle {
            visibility: visible;
          }
        }
        .rectangle {
          position: absolute;
          bottom: 0;
          left: 0;
          visibility: hidden;
          width: 100%;
          height: 4px;
          background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 395px;
      padding: 30px 55px 37px 47px;
      font-family: Industry-Demi;
      font-size: 14px;
      color: #FFFFFE;
      letter-spacing: 0;
      line-height: 27px;
      @media (max-width: $lg) {
        padding: 35px 17px 42px 17px;
      }
      p {
          font-family: Industry-Demi;
          font-size: 14px;
          color: #FFFFFE;
          letter-spacing: 0;
          line-height: 27px;
          margin-right: 15px;
          @media (max-width: $lg) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.row1 {
          margin: 0 0 18px 0;
          @media (max-width: $lg) {
            flex-wrap: nowrap;
          }
        }
        &.row2 {
          margin: 14px 0 33px 0;
          @media (max-width: $lg) {
            p {
              padding-bottom: 5px;
            }
          }
        }
      }
      .submit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: StampedeCondensed-Bold;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 16px;
        width: 146px;
        height: 40px;
        outline: none;
        background: transparent;
        border: 2px solid #006fda;
        box-shadow: 0 0 4px 4px rgba(255,255,255,0.10);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          background: #006FDA;
          box-shadow: 0 0 4px 4px rgba(101,177,251,0.20);
          color: #FFFFFF;
        }
        @media (max-width: $lg) {
          font-size: 14px;
          line-height: 14px;
          width: 140px;
          height: 38px;
        }
      }
      .form {
        &__title {
          font-family: Industry-Demi;
          font-size: 14px;
          color: #FFFFFE;
          letter-spacing: 0;
          line-height: 21px;
          margin-bottom: 19px;
          @media (max-width: $lg) {
            font-size: 12px;
            line-height: 19px;
          }
        }
        &__checkmark--container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        &__checkmark {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
          margin-bottom: 10px;
          cursor: pointer;
          &--box {
            width: 18px;
            height: 18px;
            background: transparent;
            border: 2px solid #FFFFFE;
            margin-right: 10px;
            @media (max-width: $lg) {
              width: 14px;
              height: 14px;
            }
            &.active {
              background: #006fda;
              box-shadow: 0 0 4px 4px rgba(255,255,255,0.10);
            }
          }
          &--title {
            font-family: Industry-Medium;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 14px;
            @media (max-width: $lg) {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
      #suiteUpgrade {
        .group {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;
          label {
            font-family: Industry-Demi;
            font-size: 14px;
            color: #C1C1C1;
            letter-spacing: 0;
            line-height: 27px;
            &.error {
              color: red;
            }
            @media (max-width: $lg) {
              font-size: 12px;
              line-height: 25px;
            }
          }
          input {
            font-family: Industry-Demi;
            font-size: 16px;
            color: #FFFFFE;
            letter-spacing: 0;
            height: 35px;
            line-height: 27px;
            padding-bottom: 12px;
            outline: none;
            background: transparent;
            border: none;
            border-bottom: 2px solid #FFFFFF;
            @media (max-width: $lg) {
              font-size: 14px;
              line-height: 25px;
            }
            &:focus {
              border-bottom: 2px solid #006fda;
            }
          }
          textarea {
            font-family: Industry-Demi;
            font-size: 16px;
            color: #FFFFFE;
            letter-spacing: 0;
            height: 35px;
            line-height: 27px;
            padding-bottom: 12px;
            outline: none;
            background: transparent;
            border: none;
            border-bottom: 2px solid #FFFFFF;
            @media (max-width: $lg) {
              font-size: 14px;
              line-height: 25px;
            }
            &:focus {
              border-bottom: 2px solid #006fda;
            }
          }
        }
        .submit-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: StampedeCondensed-Bold;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          height: 40px;
          width: 146px;
          background: transparent;
          border: 2px solid #006fda;
          box-shadow: 0 0 4px 4px rgba(255,255,255,0.10);
          outline: none;
          margin-top: 30px;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:hover {
            background: #006FDA;
            box-shadow: 0 0 4px 4px rgba(101,177,251,0.20);
            color: #FFFFFF;
          }
          @media (max-width: $lg) {
            font-size: 14px;
            line-height: 14px;
            width: 140px;
            height: 38px;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  #GameDayForm2 {
    .row1 {
      .custom-select {
        width: 70px;
        color: #FFFFFF;
        background-color: transparent;
        background-image: url('~@/assets/ColtsGameDay/down-arrow.svg') ;
        background-size: 15px;
        background-repeat: no-repeat;
        border: 2px solid #FFFFFF;
        border-radius: 0;
        line-height: 20px;
        outline: none;
      }
    }
    .row2 {
      .custom-select {
        width: 290px;
        color: #FFFFFF;
        background-color: transparent;
        background-image: url('~@/assets/ColtsGameDay/down-arrow.svg') ;
        background-size: 15px;
        background-repeat: no-repeat;
        border: 2px solid #FFFFFF;
        border-radius: 0;
        line-height: 20px;
        outline: none;
        @media (max-width: $lg) {
          width: 100%;
          max-width: 290px;
        }
      }
    }
  }
</style>