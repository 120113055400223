<template>
  <div>
    <div v-if="barLoader" id="bar-loading-wrapper">
      <div class="loading-prog">
        <img class="home__premium-hosp" src="#" />
        <h1></h1>
        <div id="loading-filter" :style="`left: ${loaderProgress}%;`"></div>
      </div>
      <div id="loading-bar">
        <div id="loading-bar-antiprog" :style="`left: ${100 - loaderProgress}%;`"></div>
        <div id="loading-bar-prog" :style="`right: ${100 - loaderProgress}%;`"></div>
      </div>
    </div>
    <div v-else id="spinner-loading-wrapper">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { preloadData } from "@/data/preloadData";
export default {
  name: "SiteLoader",
  data() {
    return {
      loaderProgress: 0,
      loaderProgressQueue: 0,
      preloadImageUrls: preloadData().images,
      preloadFontsArray: preloadData().fonts,
      preloadMinimumFinished: false,
      minLoadMilli: 2000,
      digestLoaderInterval: null
    };
  },
  props: {
    barLoader: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    loaderProgress: function(newVal, oldVal) {
      if (newVal > 99) {
        this.$emit("setLoaderFinished", true);
      }
    }
  },
  computed: {
    ...mapState(["global"])
  },
  mounted() {
    //add items to preload in src/data/preloadData.js
    this.preloadEverything();
  },
  methods: {
    preloadEverything() {
      setTimeout(() => {
        this.preloadMinimumFinished = true;
      }, 800);

      const itemIncrementPercentage =
        (1 / (this.preloadFontsArray.length + this.preloadImageUrls.length)) *
        100;

      //loader progress starts at item increment percentage so that bar is full when loading last item instead of 100-itemincrement
      this.loaderProgress += itemIncrementPercentage;
      this.preloadFonts(this.preloadFontsArray, itemIncrementPercentage, () => {
        this.setLoadedItem({
          loadedProperty: "fonts",
          isLoaded: true
        });
      });

      this.preloadImages(this.preloadImageUrls, itemIncrementPercentage, () => {
        this.setLoadedItem({
          loadedProperty: "images",
          isLoaded: true
        });
      });
      this.digestLoaderInterval = setInterval(() => {
        this.digestLoaderProgress();
      }, 50);
    },
    digestLoaderProgress(itemIncrementPercentage) {
      const incrementMilli = this.minLoadMilli / 100;
      let currentTimeout = 0;
      let that = this;

      if (
        this.loaderProgress >= 100 ||
        (this.preloadMinimumFinished & this.global.loadedItems.images &&
          this.global.loadedItems.fonts)
      ) {
        clearInterval(this.digestLoaderInterval);
        return;
      }

      const amountToDigest = this.loaderProgressQueue;
      for (let i = 0; i < amountToDigest - 1; i++) {
        if (this.loadersProgress >= 100) break;

        if (100 - this.loaderProgress < itemIncrementPercentage) {
          this.loaderProgress = 100;
          break;
        } else {
          setTimeout(function() {
            that.loaderProgress += 1;
          }, currentTimeout + incrementMilli);
          currentTimeout += incrementMilli;
        }
      }
      this.loaderProgressQueue -= amountToDigest;
    },
    incrementLoaderProgress(itemIncrementPercentage) {
      this.loaderProgressQueue += itemIncrementPercentage;
    },
    preloadFonts(fonts, itemIncrementPercentage, allFontsLoadedCallback) {
      if (fonts.length < 1) {
        this.setLoadedItem({
          loadedProperty: "fonts",
          isLoaded: true
        });
        return;
      }
      let loadedCounter = 0;
      fonts.forEach(font => {
        font
          .load()
          .then(loadedFont => {
            loadedCounter++;
            this.incrementLoaderProgress(itemIncrementPercentage);
            document.fonts.add(loadedFont);
            if (loadedCounter === fonts.length) {
              allFontsLoadedCallback();
            }
          })
          .catch(() => {
            loadedCounter++;
            this.incrementLoaderProgress(itemIncrementPercentage);
            if (loadedCounter === fonts.length) {
              allFontsLoadedCallback();
            }
          });
      });
    },
    preloadImages(urlArray, itemIncrementPercentage, allImagesLoadedCallback) {
      if (urlArray.length < 1) {
        this.setLoadedItem({
          loadedProperty: "images",
          isLoaded: true
        });
        return;
      }
      let loadedCounter = 0;
      urlArray.forEach(url => {
        preloadImage(url, () => {
          loadedCounter++;
          this.incrementLoaderProgress(itemIncrementPercentage);
          if (loadedCounter === urlArray.length) {
            allImagesLoadedCallback();
          }
        });
      });
      function preloadImage(url, imageLoadedCallback) {
        let img = new Image();
        img.onload = imageLoadedCallback;
        img.onerror = imageLoadedCallback;
        img.src = url;
      }
    },
    ...mapActions(["setLoadedItem"])
  }
};
</script>

<style lang="scss" scoped>
$color1: #ffffff;
$color2: #023460;
$color3: #d1d1d1;
$fontColor: #ffffff;
$backgroundColor: #1b1b1b;
$primary-color-dark: #1b1b1b;

#bar-loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // background-image: url("~@/assets/images/backgrounds/bg-pattern-1-compressed.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $primary-color-dark;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loading-prog {
    position: relative;
    overflow: hidden;
    width: 450px;
    max-width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    #loading-filter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.4;
      background: $primary-color-dark;
      opacity: 0.95;
      border-radius: 4px;
    }

    img {
      height: auto;
      width: 450px;
      max-width: 90vw;
      position: relative;
      // left: 2%;
    }
  }
  #loading-bar {
    position: relative;
    height: 2px;
    width: 450px;
    max-width: 90vw;
    padding: 0;
    border-radius: 24px;
    margin-top: 50px;
    overflow: hidden;
    #loading-bar-prog {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      background: #fff;
      border-radius: 24px;
    }
    #loading-bar-antiprog {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 24px;
      opacity: 0.15;
    }
  }
}

#spinner-loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $backgroundColor;
  z-index: 999;

  #loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $fontColor;
    width: 100px;
    height: 30px;
    margin: -7px 0 0 -45px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid #f00;
  }

  #loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid #0f0;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }

  #loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid #00f;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }

  #loading-content {
    border: 3px solid transparent;
    border-top-color: $color3;
    border-bottom-color: $color3;
    border-radius: 50%;
    animation: loader 2s linear infinite;
  }

  #loading-content:before {
    border: 3px solid transparent;
    border-top-color: $color2;
    border-bottom-color: $color2;
    border-radius: 50%;
    animation: loader 3s linear infinite;
  }

  #loading-content:after {
    border: 3px solid transparent;
    border-top-color: $color1;
    border-bottom-color: $color1;
    border-radius: 50%;
    animation: loader 3s linear infinite;
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>