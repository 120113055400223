<template>
  <section id="Events">
    <div id="EventsMarker"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
      <div class="title">
        <h1>Upcoming</h1>
        <h2>Events</h2>
      </div>
      <span>
        Stay on top of all Colts Suite events, Colts game days and non-Colts events at Lucas
        Oil Stadium
      </span>
      <div class="contentRow row1">
        <div class="header">
          <div class="rectangle"></div>
          <h3>Non-Colts Events</h3>
          <div class="rectangle"></div>
        </div>
        <div
          class="cards cards1"
          v-if="
            ((topCards.length < 4) && (windowWidth > 992)) ||
            ((topCards.length < 2) && (windowWidth <=992)) ||
            ((topCards.length < 2) && (windowWidth <= 650))
          "
        >
          <div class="card" v-for="(card, index) in topCards" :key="'card' + index">
            <div class="top">
              <img v-if="card.image" :src="require('@/assets/Events/' + card.image)">
            </div>
            <div class="bottom">
              <h4 v-html="card.title"></h4>
              <p
                v-for="(date, index) in card.dates"
                :key="'date' + index"
              >
                {{ date }}
              </p>
            </div>
          </div>
        </div>
        <EventsCarousel v-else :data="topCards" />
      </div>
      <div class="contentRow row2" v-if="bottomCardsActive">
        <div class="header">
          <div class="rectangle"></div>
          <h3>Suite Holder Events</h3>
          <div class="rectangle"></div>
        </div>
        <div
          class="cards cards2"
          v-if="
            ((bottomCards.length < 4) && (windowWidth > 992)) || 
            ((bottomCards.length < 3) && (windowWidth <=992 && windowWidth > 650)) ||
            ((bottomCards.length < 2) && (windowWidth <= 650))
          "
        >
          <div class="card" v-for="(card, index) in bottomCards" :key="'bottom' + index">
            <div class="top">
              <img v-if="card.image" :src="require('@/assets/Events/' + card.image)">
            </div>
            <div class="bottom">
              <h4 v-html="card.title"></h4>
              <p
                v-for="(date, index) in card.dates"
                :key="'date' + index"
              >
                {{ date }}
              </p>
            </div>
          </div>
        </div>
        <EventsCarousel v-else :data="bottomCards" />
      </div>
    </div>
  </section>
</template>

<script>
  import EventsCarousel from "@/components/sections/Events/EventsCarousel";
  import { mapState } from "vuex";

  export default {
    name: "Events",
    components: {
      EventsCarousel
    },
    computed: {
      ...mapState(["global"])
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        topCards: [
          {
            image: "MonsterJam_2.jpg",
            title: "Monster Jam",
            dates: [
              "6/12/21 - 12:00pm & 7:00pm",
              "6/13/21 - 3:00pm"
            ],
            active: true
          },
          {
            image: "gunzNroses.jpg",
            title: "Guns N' Roses 2021 Tour",
            dates: [
              "9/8/21 - 6:00pm"
            ],
            active: true
          },
          {
            image: "Big-Ten-Football-Championship.jpg",
            title: "Big Ten Football Championship",
            dates: [
              "12/4/21 - 8:00pm"
            ],
            active: true
          }
        ],
        bottomCards: [
          {
            image: "Riviera-Maya.jpg",
            title: "Annual Suite Holder Trip",
            dates: [
              "10/22/21 - 10/25/21"
            ]
          }
        ],
        bottomCardsActive: false
      }
    },
    created() {
      let benefits = this.global.userInfo.benefits;

      benefits.forEach((benefit) => {
        switch(benefit.description) {
          case "B":
            this.bottomCardsActive = true;
            break;
        }
      })
    },
    mounted() {
      let that = this
      window.addEventListener(
        "resize",
        function() {
          that.windowWidth = window.innerWidth
        },
        false
      )
    }
  }
</script>

<style lang="scss" scoped>
  #Events {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: url('~@/assets/Events/white-textured-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 105px 0 101px 160px;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      background-image: url('~@/assets/Events/events-mobile-bg.jpg');
      padding: 71px 20px 49px 20px;
    }
    #EventsMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      @media (max-width: $lg) {
        width: 100%;
        align-items: flex-start;
      }
      .title {
        margin-bottom: 32px;
        h1 {
          font-family: StampedeCondensed-Bold;
          font-size: 80px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #023460;
          letter-spacing: 0;
          line-height: 68px;
          @media (max-width: $lg) {
            font-size: 60px;
            line-height: 50px;
          }
          @media (max-width: 374px) {
            font-size: 48px;
            line-height: 39px;
          }
        }
        h2 {
          font-family: StampedeCondensed-Bold;
          font-size: 55px;
          color: #023460;
          letter-spacing: 0;
          line-height: 52px;
          margin: -21px 0 0 81px;
          @media (max-width: $lg) {
            font-size: 40px;
            line-height: 40px;
            margin: -15px 0 0 35px;
          }
        }
      }
      span {
        font-family: Industry-Medium;
        font-size: 14px;
        color: #1D252C;
        letter-spacing: 0;
        line-height: 14px;
        margin-bottom: 40px;
        @media (max-width: $lg) {
          line-height: 26px;
        }
      }
      .contentRow {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        &.row1 {
          margin-bottom: 53px;
          @media (max-width: $lg) {
            margin-bottom: 20px;
          }
        }
        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 25px;
          @media (max-width: $lg) {
            margin-bottom: 20px;
          }
          h3 {
            font-family: Stampede-Regular;
            font-size: 16px;
            color: #AAAFAF;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            width: 188px;
          }
          .rectangle {
            height: 1px;
            width: calc(50% - 94px);
            background: #D1D1D1;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
          }
        }
        .cards {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: fit-content;
          &.cards1 {
            // justify-content: center;
            .card {
              margin-right: 25px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          &.cards2 {
            justify-content: center;
          }
          .card {
            height: 100%;
            width: calc((100% / 3) - 35px);
            @media (max-width: $lg) {
              margin-bottom: 20px;
              width: 100%;
              max-width: 281px;
            }
            .top {
              height: 182px;
              width: 100%;
              background-image: linear-gradient(180deg, #023460 0%, #023460 100%);
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                // max-height: 120px;
                object-fit: contain;
              }
            }
            .bottom {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: calc(100% - 182px);
              width: 100%;
              background: #1D252C;
              box-shadow: 0 6px 30px 0 rgba(0,0,0,0.19);
              padding: 13px 14px 12px 15px;
              h4 {
                font-family: StampedeCondensed-Bold;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 18px;
                margin-bottom: 10px;
              }
              p {
                font-family: Industry-Medium;
                font-size: 14px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
</style>