<template>
  <section id="ColtsGameDay">
    <div id="ColtsGameDayMarker"></div>
    <div class="brick"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300">
      <div class="title">
        <h1>All Things</h1>
        <img src="@/assets/ColtsGameDay/ColtsGameDay.png" alt="all things colts game day">
      </div>
      <div class="top">
        <div class="tiles">
          <a
            class="tile"
            v-for="(tile, index) in tiles"
            :key="'tile' + index"
            :href="tile.link"
            target="_blank"
          >
            <img class="tile__image" svg-inline :src="require('@/assets/ColtsGameDay/' + tile.image)">
            <div class="name" v-html="tile.name"></div>
            <div v-if="tile.info" class="info" v-html="tile.info"></div>
            <div class="hover-effect"></div>
          </a>
        </div>
        <div class="right">
          <GameDayForm1 />
        </div>
      </div>
      <div class="bottom">
        <div class="player-mobile">
          <img src="@/assets/ColtsGameDay/Hines-cutout-small.png" alt="hines">
          <div class="mobile-lines">
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
        <div class="left">
          <GameDayForm2 />
        </div>
        <div class="right">
          <img src="@/assets/ColtsGameDay/Hines-cutout-small.png" alt="hines">
          <div class="side-lines">
            <div class="side-line"></div>
            <div class="side-line"></div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>
  import GameDayForm1 from "@/components/sections/ColtsGameDay/GameDayForm1";
  import GameDayForm2 from "@/components/sections/ColtsGameDay/GameDayForm2";

  export default {
    name: "ColtsGameDay",
    components: {
      GameDayForm1,
      GameDayForm2
    },
    data() {
      return {
        tiles: [
          {
            image: "Colts-Account-Manager.svg",
            name: "Colts Account Manager",
            info: "Log in to manage tickets,<br>pay invoices and edit account<br>information if needed.",
            link: "https://am.ticketmaster.com/colts/#/"
          },
          {
            image: "Colts-pro-shop.svg",
            name: "Colts Pro Shop",
            link: "https://shop.colts.com/?_s=bm-colts-topnav-shop-042319"
          },
          {
            image: "gameday-info.svg",
            name: "General Game Day<br>Information",
            link: "https://www.colts.com/game-day/"
          },
          {
            image: "Clear-Bag.svg",
            name: "NFL Clear Bag Policy",
            link: "https://www.nfl.com/legal/clear-bag-policy"
          },
          {
            image: "ticket-management.svg",
            name: "Mobile Ticketing Guide",
            link: "https://www.colts.com/tickets/mobile-ticketing"
          },
          {
            image: "catering-icon.svg",
            name: "Gameday Suite Catering<br>(Centerplate)",
            link: "https://lucasoilstadium.ezplanit.com/#/welcome"
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  #ColtsGameDay {
    position: relative;
    background-image: url('~@/assets/ColtsGameDay/colts-gameday-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 105px 0 100px 17.8%;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      // background-image: url('~@/assets/ColtsGameDay/PREMIUM-COLTS-BG.jpg');
      background-image: url('~@/assets/ColtsGameDay/gameday-mobile-bg-small.jpg');
      padding: 62px 30px 63px 20px;
    }
    #ColtsGameDayMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 500px;
      @media (max-width: $lg) {
        width: 100%;
        min-width: auto;
        align-items: center;
      }
      .title {
        margin-bottom: 41px;
        h1 {
          font-family: StampedeCondensed-Bold;
          font-size: 80px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #FFFFFF;
          letter-spacing: 0;
          line-height: 68px;
          @media (max-width: $lg) {
            font-size: 60px;
            line-height: 50px;
          }
          @media (max-width: 374px) {
            font-size: 48px;
            line-height: 39px;
          }
        }
        img {
          margin: -18px 0 0 12px;
          @media (max-width: $lg) {
            width: 250px;
            margin: -16px 0 0 28px;
          }
          @media (max-width: 374px) {
            width: 218px;
            margin: -12px 0 0 28px;
          }
        }
      }
      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-right: 10%;
        @media (max-width: $lg) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 0;
        }
        .tiles {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 473.5px;
          @media (max-width: $lg) {
            width: 100%;
            max-width: 491px;
            justify-content: space-around;
          }
          @media (max-width: 374px) {
            flex-direction: column;
          }
          .tile {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 220px;
            height: 220px;
            background-color: rgba(29,37,44,0.7);
            margin-bottom: 34px;
            padding-bottom: 18px;
            text-decoration: none;
            outline: none;
            transition: all 0.3s linear;
            @media (max-width: $lg) {
              margin-bottom: 25px;
              width: 200px;
              height: 200px;
            }
            @media (max-width: 450px) {
              width: 100%;
              height: 200px;
            }
            &:last-child {
              @media (min-width: 374px) {
                margin-bottom: 0;
              }
            }
            &:hover {
              background-color: rgba(17,22,26,0.7);
              .hover-effect {
                opacity: 1;
              }
            }
            .hover-effect {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 4px;
              background-image: linear-gradient(270deg, #D8D8D8 0%, #F7F7F7 33%, #D8D8D8 100%);
              box-shadow: 0 0 4px 4px rgba(255,255,255,0.20);
              opacity: 0;
              transition: opacity 0.3s linear;
            }
            .name {
              font-family: Industry-Bold;
              font-size: 14px;
              color: #FFFFFE;
              letter-spacing: 0;
              text-align: center;
              line-height: 19px;
              margin-top: 7px;
              @media (max-width: $lg) {
                font-size: 12px;
                line-height: 16px;
              }
            }
            .info {
              font-family: Industry-Bold;
              font-size: 12px;
              color: #FFFFFE;
              letter-spacing: 0;
              text-align: center;
              line-height: 19px;
              margin-top: 6px;
              @media (max-width: $lg) {
                font-size: 10px;
                line-height: 14px;
              }
            }
            &__image {
              height: 100px;
              width: 100px;
              object-fit: contain;
              @media (max-width: $lg) {
                width: 90px;
                height: 90px;
              }
              @media (max-width: 374px) {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
        .right {
          display: flex;
          justify-content: center;
          width: calc(100% - 512px);
          @media (max-width: $lg) {
            width: 100%;
            margin: 25px 0;
          }
          @media (max-width: 374px) {
            margin-top: 0;
          }
        }
      }
      .bottom {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: $lg) {
          flex-direction: column;
        }
        .player-mobile {
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          @media (min-width: $lg) {
            display: none;
          }
          img {
            z-index: 2;
            max-width: 90%;
            max-height: 700px;
            object-fit: contain;
          }
          .mobile-lines {
            position: absolute;
            top: 50%;
            right: -30px;
            transform: translateY(-50%);
            width: 50%;
          }
        }
        .right {
          display: flex;
          align-items: flex-start;
          width: calc(100% - 500px);
          @media (max-width: $lg) {
            display: none;
          }
          .side-lines {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 50%;
            .side-line {
              height: 80px;
              width: 75%;
              background: #FFFFFF;
              @media (max-width: 1515px) {
                width: 50%;
              }
              @media (max-width: $xl) {
                width: 25%;
              }
              &:first-child {
                margin-bottom: 35px;
              }
            }
          }
          img {
            z-index: 2;
            max-height: 880px;
            object-fit: contain;
          }
        }
      }
    }
  }
</style>