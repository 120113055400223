<template>
  <section id="Faq">
    <div id="FaqMarker"></div>
    <div class="brick"></div>
    <div class="content" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
      <div class="title">
        <h1>Colts Suite</h1>
        <img src="@/assets/FAQ/HolderFAQs.png" alt="holders faq">
      </div>
      <Accordion :accordionData="accordionData" />
    </div>
  </section>
</template>

<script>
  import Accordion from "@/components/sections/Faq/FaqAccordion"
  export default {
    name: "Faq",
    components: {
      Accordion
    },
    data() {
      return {
        accordionData: [
          {
            title: "Can I purchase my suite for other events at Lucas Oil Stadium?",
            points: [
              "Yes, Colts suite holders typically have access to their suites for most non-Colts events. It will vary depending on the event but more information will be provided to you when applicable."
            ]
          },
          {
            title: "Will I still receive email communications regarding updates througout the year?",
            points: [
              "Yes! While you can access this suite portal for a lot of your suite membership information, Colts staff will still communicate via email, phone or text with any and all pertinent updates. Updates can include but are not limited to: event invites, team news, special offers, game day information and more."
            ]
          },
          {
            title: "What time of year will I be invoiced for my suite and what forms of payment are accepted?",
            points: [
              "Standard suite installments are due March 15 and May 15 of each season. You will be notified when an installment invoice is active.",
              "Invoices can be paid by check, credit card or ACH payments."
            ]
          },
          {
            title: "Will suite tickets be fully mobile?",
            points: [
              "The Indianapolis Colts highly encourage and recommend the use of mobile ticketing. You can access your tickets via the online Account Manager page and easily manage tickets for every game. Non-Colts event mobile ticketing policies are at the discretion of the event promoter."
            ]
          },
          {
            title: "What access does the guest pass and/or host pass receive?",
            points: [
              "A suite host pass grants entry into the stadium and onto suite level. A suite guest pass does NOT grant entry into the stadium and must be accompanied by a stadium ticket. Once into the stadium, a suite guest pass will provide access to suite levels."
            ]
          },
          {
            title: "How and when do I purchase catering?",
            points: [
              "Centerplate is the exclusive caterer at Lucas Oil Stadium. Each year, you will be contacted directly by Centerplate with the suite menu and instructions on ordering catering online. We recommend placing your catering order in advance but orders can be placed on game days with a limited menu."
            ]
          },
          {
            title: "Are there VIP entrances for suite holders?",
            points: [
              "Yes, there are seven VIP entrances located around Lucas Oil Stadium. Each entrance is marked with club/suite access."
            ]
          },
          {
            title: "When can I access my suite on game day?",
            points: [
              "Gates open two hours prior to kickoff. You and your guests may start accessing your suite two hours prior to kickoff. Please note all Colts kickoff times are Eastern Time. "
            ]
          },
          {
            title: "Can I purchase additional tickets with access to my suite?",
            points: [
              "Each suite is allowed to purchase a certain quantity of standing room only (SRO) tickets. To maximize the capacity of your suite, you can order up to a maximum number of SROs. SROs can be purchased on a season basis or single game basis and pricing will vary based on the specific game. "
            ]
          },
          {
            title: "Do Colts suite holders receive access to their suite for home playoff game(s)?",
            points: [
              "Yes, should the Colts host a playoff game(s), all suite holders are required to purchase their playoff suite tickets per the terms of the suite license agreement."
            ]
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  #Faq {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-image: url('~@/assets/FAQ/faq-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 105px 10% 101px 160px;
    @media (min-width: $lg) {
      background-attachment: fixed;
    }
    @media (max-width: $lg) {
      // background-image: url('~@/assets/FAQ/faq-mobile-bg.jpg');
      background-image: url('~@/assets/FAQ/faq-mobile-bg-small.jpg');
      padding: 70px 30px 57px 20px;
    }
    #FaqMarker {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      @media (max-width: $lg) {
        top: -60px;
      }
    }
    .content {
      width: 90%;
      @media (max-width: $lg) {
        width: 100%;
      }
      .title {
        margin-bottom: 47px;
        h1 {
          font-family: StampedeCondensed-Bold;
          font-size: 80px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #FFFFFF;
          letter-spacing: 0;
          line-height: 68px;
          @media (max-width: $lg) {
            font-size: 60px;
            line-height: 50px;
          }
          @media (max-width: 374px) {
            font-size: 45px;
            line-height: 40px;
          }
        }
        img {
          margin: -22px 0 0 78px;
          @media (max-width: $lg) {
            width: 250px;
            margin: -15px 0 0 30px;
          }
          @media (max-width: 374px) {
            // width: 222px;
            max-width: 90%;
            margin: -12px 0 0 15px;
          }
        }
      }
    }
  }
</style>